<template>
  <section :class="$style.underOrder" id="underOrder">
    <img :class="$style.bg" src="@/assets/images/custom-made-bg.png" />
    <div :class="[$style.content, { [$style.content__stepThree]: step === 3 }]">
      <Title
        v-if="step <= 2"
        position="center"
        color="white"
        weight="bold-white"
      >
        ДВЕРИ ПО ИНДИВИДУАЛЬНОМУ ЗАКАЗУ
      </Title>
      <div v-if="step <= 2" :class="$style.step">{{ `Шаг ${step} из 2` }}</div>
      <h3 v-if="step <= 2" :class="$style.subtitle">
        {{ step === 1 ? `Выберите тип двери` : `Уточните детали` }}
      </h3>
      <Button
        v-if="step === 1"
        :class="$style.left"
        type="quaternary"
        @click="showPrev"
      >
        <img src="@/assets/icons/arrowWhite.svg" />
      </Button>
      <VueSlickCarousel
        v-if="step === 1"
        :class="$style.carousel"
        ref="underOrderCarousel"
        v-bind="settings"
      >
        <div
          :class="$style.slider"
          v-for="door in doorsData"
          :key="door.id"
          @click="getDoor(door)"
        >
          <Slider :door="door" :selectedDoor="selectedDoor" />
        </div>
      </VueSlickCarousel>
      <Button
        v-if="step === 1"
        :class="$style.right"
        type="quaternary"
        @click="showNext"
      >
        <img :class="$style.icon" src="@/assets/icons/arrowWhite.svg" />
      </Button>
      <Form v-if="step === 2" />
      <Total v-if="step === 3" @changeStep="changeStep" />
      <div :class="$style.buttons">
        <Button
          v-if="step === 2"
          :class="[$style.button, $style.button__back]"
          type="tertiary"
          color="brown"
          :arrow="true"
          :arrowLeft="true"
          @click="prevStep"
        >
          Назад
        </Button>
        <Button
          v-if="step <= 2"
          :class="$style.button"
          type="tertiary"
          color="brown"
          :arrow="true"
          :disabled="Object.keys(selectedDoor).length === 0 ? 'disabled' : ''"
          @click="nextStep"
        >
          Далее
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'
import Slider from '@/components/common/UnderOrderStep1'
import Form from '@/components/common/UnderOrderStep2'
import Total from '@/components/common/UnderOrderStep3'
import constants from '@/application/underOrder'

import { mapMutations } from 'vuex'

export default {
  components: {
    Title,
    Button,
    Slider,
    Form,
    Total
  },
  data() {
    return {
      selectedDoor: {},
      step: 1,
      settings: {
        arrows: false,
        dots: false,
        draggable: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              draggable: true,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    doorsData() {
      return constants.DOORS
    }
  },
  methods: {
    ...mapMutations(['setDoor']),

    getDoor(door) {
      this.selectedDoor = door
    },
    showNext() {
      this.$refs.underOrderCarousel.next()
    },
    showPrev() {
      this.$refs.underOrderCarousel.prev()
    },
    nextStep() {
      if (this.step === 1) {
        this.setDoor(this.selectedDoor)
        this.step += 1
      } else {
        this.step += 1
      }
    },
    prevStep() {
      this.step -= 1
    },
    changeStep() {
      this.step = 1
      this.selectedDoor = {}
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.underOrder {
  position: relative;
  width: 100%;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 89.375rem;
    height: 100%;
    margin: 0 auto;
    padding: 6rem 1rem 6.25rem;

    &__stepThree {
      justify-content: center;
      min-height: 53.25rem;
    }

    .step {
      position: relative;
      margin: 1.2rem 0 1.5rem;
      padding: 0.15rem 1.35rem 0.1rem;
      background: $white;
      @include subtitle();
      font-size: 0.75rem;
      word-spacing: 2px;
      transform: scale(1, 1.4);

      &::before {
        content: '';
        position: absolute;
        left: 48%;
        bottom: -0.15rem;
        width: 0.5rem;
        height: 0.5rem;
        z-index: -1;
        background: $white;
        transform: rotate(45deg);
      }
    }

    .subtitle {
      @include subtitleText();
      font-size: 1.5rem;
      color: $white;
    }

    .icon {
      transform: rotate(180deg);
    }

    .left {
      position: absolute;
      top: 50%;
      left: 1rem;
    }

    .right {
      position: absolute;
      top: 50%;
      right: 1rem;
    }

    .carousel {
      width: 88%;

      .slider {
        position: relative;
        height: 29rem;
        outline: none;
        cursor: pointer;

        &:hover {
          &::before {
            opacity: 1;
          }

          &::after {
            opacity: 1;
          }
        }

        &:before {
          opacity: 0;
          content: '';
          position: absolute;
          top: 1%;
          left: 7%;
          height: 92%;
          width: 0.125rem;
          background: $transparent-gray;
          transition: all 0.3s ease;
        }

        &::after {
          opacity: 0;
          content: '';
          position: absolute;
          top: 1%;
          right: 7%;
          height: 92%;
          width: 0.125rem;
          background: $transparent-gray;
          transition: all 0.3s ease;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 1.6rem 0 0;

      .button {
        width: 39%;
        border: none;

        &__back {
          width: 20%;
          margin: 0 1rem 0 0;
        }
      }
    }
  }
}

@include for-tablet {
  .underOrder {
    .content {
      padding: 5rem 1rem 5rem;

      .subtitle {
        letter-spacing: -0.5px;
      }

      .carousel {
        .slider {
          padding: 0 0.25rem;
          height: 24rem;

          &::before {
            top: 7%;
            left: 3%;
            height: 80%;
          }

          &::after {
            top: 7%;
            right: 3%;
            height: 80%;
          }
        }
      }

      .buttons {
        margin: 2.2rem 0 0;

        .button {
          width: 65%;

          &__back {
            width: 35%;
          }
        }
      }
    }
  }
}

@include for-mobile {
  .underOrder {
    .bg {
      object-fit: cover;
    }

    .content {
      padding: 2.5rem 1rem 3.5rem;

      .left {
        top: 54%;
      }

      .right {
        top: 54%;
      }

      .step {
        margin: 1rem 0;
      }

      .subtitle {
        font-size: 1.25rem;
        letter-spacing: 0.2px;
      }

      .carousel {
        .slider {
          &::before {
            top: 5%;
            left: 9%;
            height: 85%;
          }

          &::after {
            top: 5%;
            right: 9%;
            height: 85%;
          }
        }
      }

      .buttons {
        margin: 0;

        .button {
          width: 50%;

          &__back {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
