<template>
  <section :class="$style.measurerCall">
    <img :class="$style.bg" src="@/assets/images/measurer-call-bg.png" alt="" />
    <img
      :class="[$style.bg, $style.tablet]"
      src="@/assets/images/measurer-call-bg-tablet.png"
      alt=""
    />
    <img
      :class="[$style.bg, $style.mobile]"
      src="@/assets/images/measurer-call-bg-mobile.png"
      alt=""
    />
    <div :class="$style.flex">
      <div :class="$style.description">
        <h2 :class="$style.title">Закажите бесплатный вызов замерщика</h2>
        <h3 :class="$style.subtitle">в удобное для Вас время</h3>
      </div>
      <Form :class="$style.form" buttonText="Заказать" />
    </div>
  </section>
</template>

<script>
import Form from '@/components/basic/Form'

export default {
  components: {
    Form
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.measurerCall {
  position: relative;
  bottom: 5.75rem;
  display: flex;
  justify-content: flex-end;
  max-width: 87.375rem;
  min-height: 16.5rem;
  margin: 0 auto;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .tablet {
    display: none;
  }

  .mobile {
    display: none;
  }

  .flex {
    display: flex;
    width: 66.25%;
    margin: 3.5rem 0 0;
  }

  .description {
    width: 39%;

    .title {
      margin: 0.25rem 0 1rem 0;
      @include bannerTitle();
      letter-spacing: 0.5px;
      line-height: 90%;
    }

    .subtitle {
      @include bannerSubtitle();
      letter-spacing: 0.25px;
    }
  }

  .form {
    width: 53%;
    height: 53%;
  }
}

@include for-tablet {
  .measurerCall {
    bottom: 10.25rem;
    min-height: 21rem;
    margin: 0 1rem;

    .bg {
      display: none;
    }

    .tablet {
      display: block;
    }

    .flex {
      flex-direction: column;
      width: 61%;
      margin: 3rem 0;

      .description {
        width: 70%;
      }

      .form {
        width: 90%;
        margin: 1.5rem 0 0;
      }
    }
  }
}

@include for-mobile {
  .measurerCall {
    min-height: 25rem;

    .tablet {
      display: none;
    }

    .mobile {
      display: block;
    }

    .flex {
      align-items: center;
      width: 100%;
      margin: 2.5rem 0;

      .description {
        width: 100%;

        .title {
          margin: 0;
          line-height: 120%;
          text-align: center;
        }

        .subtitle {
          margin: 0.75rem 0 0;
          text-align: center;
          letter-spacing: 0;
        }
      }

      .form {
        width: 86%;
        margin: 1.65rem 0 0;
      }
    }
  }
}
</style>
