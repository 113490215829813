<template>
  <div :class="$style.underOrderSlider">
    <div
      :class="[
        $style.image,
        {
          [$style.active]: selectedDoor ? selectedDoor.id === door.id : false
        }
      ]"
    >
      <img
        :class="$style.img"
        :src="require(`@/assets/images/door/${door.image}.jpg`)"
        alt="door"
      />
      <div
        :class="[
          $style.select,
          {
            [$style.active]: selectedDoor ? selectedDoor.id === door.id : false
          }
        ]"
      >
        <img
          v-if="selectedDoor ? selectedDoor.id === door.id : false"
          src="@/assets/icons/select.svg"
        />
      </div>
    </div>
    <h4 :class="$style.doorName">{{ door.name }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    door: {
      type: Object,
      default: () => {}
    },
    selectedDoor: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.underOrderSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2.25rem 0 0;

  &:hover {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }
  }

  &:before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 5%;
    left: 2%;
    width: 98%;
    height: 0.125rem;
    background: $transparent-gray;
    transition: all 0.3s ease;
  }

  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    bottom: 11.5%;
    left: 2%;
    width: 98%;
    height: 0.125rem;
    background: $transparent-gray;
    transition: all 0.3s ease;
  }

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78%;
    height: 85%;
    background: $transparent-gray;
    transition: all 0.3s ease;

    &.active {
      background: $white;
    }

    .img {
      width: 65%;
      height: 75%;
      pointer-events: none;
    }

    .select {
      position: absolute;
      left: center;
      bottom: -1rem;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.875rem;
      height: 2.875rem;
      background: $light-brown;
      transition: all 0.3s ease;

      &.active {
        background: $brown;
      }
    }
  }

  .doorName {
    margin: 2.25rem 0 0;
    @include subtitleText();
    color: $white;
  }
}

@include for-tablet {
  .underOrderSlider {
    margin: 1rem 0 0;

    &::before {
      top: 10%;
      left: -1%;
      width: 102%;
    }

    &::after {
      bottom: 16%;
      left: -1%;
      width: 102%;
    }

    .image {
      width: 89%;
      height: 74%;

      .img {
        width: 50%;
        height: 75%;
      }

      .select {
        width: 2.25rem;
        height: 2.25rem;
      }
    }

    .doorName {
      font-size: 0.884rem;
    }
  }
}

@include for-mobile {
  .underOrderSlider {
    &::before,
    &::after {
      left: 0;
      width: 100%;
    }

    .image {
      width: 76%;
    }
  }
}
</style>
