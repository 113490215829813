import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import popup from './modules/popup'
import feedbacks from './modules/feedbacks'
import contactForm from './modules/contactForm'
import calculator from './modules/calculator'
import gallery from './modules/gallery'
import location from './modules/location'

Vue.use(Vuex, axios)

export default new Vuex.Store({
  modules: {
    popup,
    feedbacks,
    contactForm,
    calculator,
    gallery,
    location
  }
})
