<template>
  <section :class="$style.feedbacks">
    <div :class="$style.point" id="feedbacks"></div>
    <Title position="center" weight="bold">ОТЗЫВЫ</Title>
    <Button :class="$style.left" type="secondary" @click="showPrev">
      <img src="@/assets/icons/arrowWhite.svg" />
    </Button>
    <Button :class="$style.right" type="secondary" @click="showNext">
      <img :class="$style.icon" src="@/assets/icons/arrowWhite.svg" />
    </Button>
    <VueSlickCarousel
      :class="$style.carousel"
      ref="ourWorksCarousel"
      v-bind="settings"
    >
      <div
        :class="$style.slider"
        v-for="feedback in allFeedbacks"
        :key="feedback.id"
      >
        <Slider
          :feedback="feedback"
          :activeFeedback="activeFeedback"
          @changeFeedback="changeFeedback"
        />
      </div>
    </VueSlickCarousel>
    <transition name="rightExit">
      <div :class="$style.feedback" v-if="active">
        <img :class="$style.icon" src="@/assets/icons/quotes.svg" />
        <p :class="$style.text">
          {{ activeFeedback.feedback }}
        </p>
      </div>
    </transition>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Slider from '@/components/common/Feedback'
import Button from '@/components/basic/Button'

import { feedbacks } from '@/application/feedbacks'

import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    Title,
    Slider,
    Button
  },
  data() {
    return {
      active: null,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        draggable: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dotsClass: 'slick-dots our-works-dot-class',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              draggable: true,
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '30%',
              focusOnSelect: true
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '10%',
              focusOnSelect: true
            }
          }
        ]
      }
    }
  },
  watch: {
    activeFeedback() {
      this.$refs.ourWorksCarousel.goTo(this.activeFeedback.id - 1)
    }
  },
  computed: {
    ...mapGetters(['allFeedbacks', 'activeFeedback'])
  },
  created() {
    this.setFeedbacks(feedbacks)
    this.setActiveFeedback(feedbacks[0])
  },
  methods: {
    ...mapMutations(['setFeedbacks', 'setActiveFeedback']),

    showNext() {
      const nextFeedback = this.allFeedbacks.find(
        feedback => feedback.id === this.activeFeedback.id + 1
      )
      this.changeActiveFeedback(nextFeedback, 0)
      this.$refs.ourWorksCarousel.next()
    },

    showPrev() {
      const prevFeedback = this.allFeedbacks.find(
        feedback => feedback.id === this.activeFeedback.id - 1
      )
      this.changeActiveFeedback(prevFeedback, this.allFeedbacks.length - 1)
      this.$refs.ourWorksCarousel.prev()
    },

    changeActiveFeedback(feedback, num) {
      if (feedback) {
        this.setActiveFeedback(feedback)
        this.active = null
        setTimeout(() => {
          this.active = feedback
        }, 0)
      } else {
        this.setActiveFeedback(this.allFeedbacks[num])
        this.active = null
        setTimeout(() => {
          this.active = this.allFeedbacks[num]
        }, 0)
      }
    },

    changeFeedback(feedback) {
      if (this.active ? this.active.id === feedback.id : false) {
        return
      } else {
        this.active = null
        this.setActiveFeedback(feedback)
        setTimeout(() => {
          this.active = feedback
        }, 0)
      }
    }
  },
  mounted() {
    this.active = this.activeFeedback
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/animations.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.feedbacks {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 89.375rem;
  min-height: 40rem;
  padding: 0 1rem;
  margin: 0 auto 5.5rem;

  .point {
    position: absolute;
    left: auto;
    right: auto;
    top: -4rem;
  }

  .left {
    position: absolute;
    top: 12.5rem;
    left: 1.25%;
    z-index: 10;
  }

  .right {
    position: absolute;
    top: 12.5rem;
    right: 1.25%;
    z-index: 10;
  }

  .icon {
    transform: rotate(180deg);
  }

  .carousel {
    width: 88%;
    margin: 1.5rem 0 0.1rem;

    .slider {
      height: 24rem;
    }
  }

  .feedback {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82%;
    min-height: 10.5rem;
    padding: 1rem 0;
    background: $transparent-brown;

    &::before {
      position: absolute;
      top: -1.1rem;
      left: -2rem;
      content: '';
      width: 106%;
      height: 1px;
      background: $line;
      opacity: 0.3;
    }

    &::after {
      position: absolute;
      bottom: -1rem;
      left: -2rem;
      content: '';
      width: 106%;
      height: 1px;
      background: $line;
      opacity: 0.3;
    }

    .icon {
      position: absolute;
      left: -4%;
      top: 12%;
      width: 10rem;
      height: 10rem;
      transform: rotate(0deg);
    }

    .text {
      width: 86%;
      @include feedback();
      line-height: 180%;
      letter-spacing: 0.13px;

      &::before {
        position: absolute;
        top: -2.25rem;
        left: -1.15rem;
        content: '';
        height: 140%;
        width: 1px;
        background: $line;
        opacity: 0.3;
      }

      &::after {
        position: absolute;
        top: -2.25rem;
        right: -1.35rem;
        content: '';
        height: 140%;
        width: 1px;
        background: $line;
        opacity: 0.3;
      }
    }
  }
}

@include for-tablet {
  .feedbacks {
    top: -6.25rem;
    padding: 0;
    margin: 0;
    min-height: 38rem;

    .left {
      left: 3%;
      top: 12rem;
    }

    .right {
      right: 3%;
      top: 12rem;
    }

    .carousel {
      width: 100%;
    }

    .feedback {
      width: 90%;
      padding: 1.75rem 0 0.5rem;

      &::before,
      &::after {
        width: 110%;
      }

      .icon {
        left: 40%;
        top: -4%;
        width: 8rem;
        height: 8rem;
      }

      .text {
        width: 90%;
        letter-spacing: 0px;
      }
    }
  }
}

@include for-mobile {
  .feedbacks {
    top: -8.25rem;

    .left {
      left: 5%;
      top: 11rem;
    }

    .right {
      right: 5%;
      top: 11rem;
    }

    .carousel {
      margin: 1rem 0 0;
    }

    .feedback {
      bottom: 2rem;
      padding: 4.5rem 0 3rem;

      &::before,
      &::after {
        display: none;
      }

      .text {
        &::before,
        &::after {
          display: none;
        }
      }

      .icon {
        top: 0.5rem;
        left: auto;
      }
    }
  }
}
</style>
