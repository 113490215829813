<template>
  <transition name="fade">
    <div v-if="statusGallery" :class="$style.gallery">
      <div :class="$style.gallery__wrapper">
        <div :class="$style.gallery__close" @click="close" />
        <VueSlickCarousel
          :class="$style.gallery__carousel"
          v-bind="settings"
          ref="galleryCarousel"
        >
          <div
            :class="$style.gallery__slider"
            v-for="image in galleryImages"
            :key="image.id"
          >
            <div :class="$style.gallery__image">
              <img :class="$style.gallery__img" :src="image.url" />
            </div>
          </div>
        </VueSlickCarousel>
        <Button
          v-if="slideIndex > 1"
          :class="[$style.gallery__btn, $style.gallery__btn_left]"
          type="secondary"
          @click="showPrev"
        >
          <img src="@/assets/icons/arrowWhite.svg" />
        </Button>
        <Button
          v-if="slideIndex < galleryImages.length"
          :class="[$style.gallery__btn, $style.gallery__btn_right]"
          type="secondary"
          @click="showNext"
        >
          <img :class="$style.icon" src="@/assets/icons/arrowWhite.svg" />
        </Button>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/basic/Button'

import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Button
  },
  data() {
    return {
      slideIndex: 1,
      settings: {
        arrows: false,
        dots: false,
        draggable: false,
        touchMove: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  },
  computed: {
    ...mapGetters(['galleryImages', 'statusGallery'])
  },
  methods: {
    ...mapMutations(['setImageIndex', 'setImages', 'changeStatusGallery']),

    close() {
      this.slideIndex = 1
      this.changeStatusGallery(false)
      document.body.style.overflow = 'visible'
    },

    showNext() {
      this.slideIndex += 1
      this.$refs.galleryCarousel.next()
    },

    showPrev() {
      this.slideIndex -= 1
      this.$refs.galleryCarousel.prev()
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $transparent-green;
  z-index: 9000;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.25rem;
    height: 3.25rem;
    background: $dark-gray;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 95%;
      height: 1px;
      background: $white;
      opacity: 0.5;
    }

    &::before {
      top: 1.5rem;
      right: 0.125rem;
      transform: rotate(45deg);
    }

    &::after {
      top: 1.5rem;
      right: 0.125rem;
      transform: rotate(135deg);
    }
  }

  &__carousel {
    width: 80%;
  }

  &__slider {
    overflow: hidden;
    height: 50rem;
    margin: 0 -0.25rem;
    outline: none;
  }

  &__image {
    height: 100%;
    padding: 0 1rem;
  }

  &__img {
    height: 100%;
    margin: 0 auto;
    pointer-events: none;
  }

  &__btn {
    position: absolute;
    top: 50%;
    z-index: 10;

    &_left {
      left: 10%;
    }

    &_right {
      right: 10%;

      .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      pointer-events: none;
    }
  }
}

@include for-tablet {
  .gallery {
    &__btn {
      &_left {
        left: 3%;
      }

      &_right {
        right: 3%;
      }
    }
  }
}

@include for-mobile {
  .gallery {
    &__slider {
      height: 30rem;
      margin: auto;
    }

    &__image {
      padding: 0;
    }

    &__img {
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
