<template>
  <label :class="$style.checkboxWrapper">
    <input
      :class="$style.checkbox"
      type="checkbox"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
    />
    <div :class="[$style.label, $style[type]]">
      <svg
        :class="$style.icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8.561 6.846"
      >
        <path
          id="Прямоугольник_13_копия_4"
          data-name="Прямоугольник 13 копия 4"
          d="M1098.53,984.162l-.248.261-2.562-2.734.977-1.027,1.836,1.958,4.77-5.043.977,1.027-5.5,5.819Z"
          transform="translate(-1095.719 -977.576)"
        />
      </svg>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.checkboxWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;

  .checkbox {
    display: none;
  }

  .checkbox:checked + .label {
    .icon {
      opacity: 1;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;
    background: $white;

    .icon {
      width: 0.55rem;
      opacity: 0;
    }

    &.primary {
      width: 0.875rem;
      height: 0.875rem;
      border: 1px solid $transparent-gray-medium;
      background: transparent;

      .icon {
        width: 0.625rem;
        height: 0.5rem;
        fill: $white;
      }
    }

    &.secondary {
      border: 1px solid $dark-gray;
      background: transparent;

      .icon {
        width: 0.5rem;
        fill: $green;
      }
    }
  }
}
</style>
