<template>
  <div :class="$style.total">
    <Title
      :class="$style.title"
      position="center"
      color="white"
      weight="bold-white"
    >
      ОРИЕНТИРОВОЧНАЯ СУММА ВАШЕГО ЗАКАЗА:
    </Title>
    <Title
      :class="$style.price"
      position="center"
      color="white"
      weight="bold-white"
    >
      {{ price(totalPrice) }}
    </Title>
    <p :class="$style.description">
      Оставьте свои данные и наш менеджер свяжется с Вами
    </p>
    <p :class="$style.description">для уточнения деталей</p>
    <p :class="$style.description">и подтверждения стоимости заказа</p>
    <p :class="$style.mobileDescription">
      Оставьте свои данные и наш менеджер свяжется с Вами для уточнения деталей
      подтверждения стоимости заказа
    </p>
    <div :class="$style.wrapper">
      <p
        :class="$style.text"
        v-for="(elem, index) in Object.values(calculator)"
        :key="index"
      >
        <span v-if="elem && elem.id">
          {{ `Дверь: "${elem.name}" - ${increasedPrice(elem.price)}` }}
        </span>
        <span v-else-if="elem && elem.name === 'width'">
          {{ `Высота: ( ${elem.value.substr(0, 8)} )` }} -
          {{ increasedPrice(elem.price) }}
        </span>
        <span v-else-if="elem && elem.name === 'height'">
          {{ `Ширина: ( ${elem.value.substr(0, 8)} )` }} -
          {{ increasedPrice(elem.price) }}
        </span>
        <span v-else-if="elem">
          {{ `${elem.value}` }} -
          {{ increasedPrice(elem.price) }}
        </span>
      </p>
    </div>
    <Form
      :class="$style.form"
      buttonType="tertiary"
      buttonText="Заказать звонок"
      :buttonLine="false"
      :buttonBorder="false"
      :calculator="true"
      @changeStep="$emit('changeStep')"
    />
  </div>
</template>

<script>
import Title from '@/components/basic/Title'
import Form from '@/components/basic/Form'

import { converter } from '@/application/converter'

import { mapGetters } from 'vuex'

export default {
  components: {
    Title,
    Form
  },

  computed: {
    ...mapGetters(['totalPrice', 'calculator', 'location'])
  },

  methods: {
    increasedPrice(num) {
      return typeof num == 'string'
        ? this.price(num)
        : num > 0
        ? this.price(num)
        : 'без доплаты'
    },

    price(num) {
      if (this.location === 'BY') {
        return this.isNumber(num) ? `${this.priceSpase(num)} бел. руб.` : num
      } else {
        return this.isNumber(num)
          ? `${this.priceSpase(num * converter.value)} рос. руб.`
          : num
      }
    },

    priceSpase(price) {
      return String(price).length > 3
        ? String(price)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
            .replace(/\.[^.]+$/, '')
        : price
    },

    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/fonts/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.total {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .price {
    margin: 0 0 1.5rem;
    text-transform: uppercase;
  }

  .wrapper {
    width: 40%;
    margin: 1.5rem 0 0.35rem;

    .text {
      margin: 0 0 0.35rem;
      @include subtitleText();
      font-size: 0.85rem;
      color: $white;
    }
  }

  .description {
    margin: 0 0 0.35rem;
    @include subtitleText();
    font-size: 1.25rem;
    color: $white;
    text-align: center;
  }

  .mobileDescription {
    display: none;
  }

  .form {
    width: 40%;
    margin: 1rem 0 0;
  }
}

@include for-tablet {
  .total {
    .wrapper {
      width: 70%;
    }
    .form {
      width: 70%;
    }
  }
}
@include for-mobile {
  .total {
    .title {
      @include subtitle();
      color: $white;
      font-size: 1.375rem;
      line-height: 160%;
    }

    .price {
      font-size: 2.125rem;
    }

    .wrapper {
      width: 100%;

      .text {
        font-size: 0.75rem;
      }
    }

    .description {
      display: none;
    }

    .mobileDescription {
      display: block;
      @include subtitleText();
      font-size: 1.25rem;
      color: $white;
      text-align: center;
    }

    .form {
      width: 100%;
      margin: 2rem 0 1rem;
    }
  }
}
</style>
