<template>
  <div :class="$style.details">
    <div :class="$style.inputs">
      <div :class="$style.section">
        <div :class="$style.item">
          <p :class="$style.subtitle" @click="openSection(1)">
            Размеры
            <span :class="$style.arrow">
              <img
                :class="{ [$style.active]: opened === 1 }"
                src="@/assets/icons/arrow-for-select.svg"
              />
            </span>
          </p>
          <transition name="topExit">
            <div
              v-show="isMobile ? opened === 1 : true"
              :class="$style.wrapper"
            >
              <Select
                :class="$style.select"
                :items="doorsWidth"
                placeholder="Ширина"
                @input="setOption"
              />
              <Select
                :class="$style.select"
                :items="doorsHeight"
                placeholder="Высота"
                @input="setOption"
              />
            </div>
          </transition>
        </div>
        <div :class="$style.wrapper">
          <div :class="$style.item">
            <p :class="$style.subtitle" @click="openSection(2)">
              Какая дверь Вам нужна?
              <span :class="$style.arrow">
                <img
                  :class="{ [$style.active]: opened === 2 }"
                  src="@/assets/icons/arrow-for-select.svg"
                />
              </span>
            </p>
            <transition name="topExit">
              <Select
                v-show="isMobile ? opened === 2 : true"
                :class="$style.select"
                :items="doorsType"
                placeholder="Выберите тип"
                @input="setOption"
              />
            </transition>
          </div>
          <div :class="$style.item">
            <p :class="$style.subtitle" @click="openSection(3)">
              Боковой добор
              <span :class="$style.arrow">
                <img
                  :class="{ [$style.active]: opened === 3 }"
                  src="@/assets/icons/arrow-for-select.svg"
                />
              </span>
            </p>
            <transition name="topExit">
              <Select
                v-show="isMobile ? opened === 3 : true"
                :class="$style.select"
                :items="sideExtansion"
                placeholder="Выберите добор"
                @input="setOption"
              />
            </transition>
          </div>
          <!-- <div :class="$style.item">
            <p :class="$style.subtitle" @click="openSection(3)">
              Материал
              <span :class="$style.arrow">
                <img
                  :class="{ [$style.active]: opened === 3 }"
                  src="@/assets/icons/arrow-for-select.svg"
                />
              </span>
            </p>
            <transition name="topExit">
              <Select
                v-show="isMobile ? opened === 3 : true"
                :class="$style.select"
                :items="material"
                placeholder="Выберите материал"
                @input="setOption"
              />
            </transition>
          </div> -->
        </div>
      </div>
      <div :class="$style.section">
        <div :class="$style.item">
          <p :class="$style.subtitle" @click="openSection(4)">
            Дополнительные элементы
            <span :class="$style.arrow">
              <img
                :class="{ [$style.active]: opened === 4 }"
                src="@/assets/icons/arrow-for-select.svg"
              />
            </span>
          </p>
          <transition name="topExit">
            <div
              v-show="isMobile ? opened === 4 : true"
              :class="$style.wrapper"
            >
              <div :class="$style.flex">
                <p :class="$style.text">Дверные петли</p>
                <Select
                  :class="$style.select"
                  :items="doorsHinges"
                  placeholder="Выберите петли"
                  @input="setOption"
                />
              </div>
              <div :class="$style.flex">
                <p :class="$style.text">Замок</p>
                <Select
                  :class="$style.select"
                  :items="doorsLock"
                  placeholder="Выберите замок"
                  @input="setOption"
                />
              </div>
              <div :class="$style.flex">
                <p :class="$style.text">Фурнитура</p>
                <div
                  :class="$style.checkbox"
                  v-for="item in hardware"
                  :key="item.id"
                >
                  <Checkbox
                    type="primary"
                    :value="item.active"
                    @input="e => setService(e, item)"
                  />
                  <span :class="$style.description">
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div :class="$style.item">
      <p :class="$style.subtitle" @click="openSection(5)">
        Дополнительные услуги
        <span :class="$style.arrow">
          <img
            :class="{ [$style.active]: opened === 5 }"
            src="@/assets/icons/arrow-for-select.svg"
          />
        </span>
      </p>
      <transition name="topExit">
        <div v-show="isMobile ? opened === 5 : true" :class="$style.checkboxes">
          <div>
            <div
              :class="$style.checkbox"
              v-for="delivery in deliveryPrice"
              :key="delivery.id"
            >
              <Checkbox
                :value="delivery.id === activeDeliveryId"
                type="primary"
                @input="e => setDeliveryType(e, delivery)"
              />
              <span :class="$style.description">
                {{ delivery.value }}
              </span>
            </div>
          </div>
          <div>
            <div
              :class="$style.checkbox"
              v-for="service in additionalServices.slice(0, 3)"
              :key="service.id"
            >
              <Checkbox
                type="primary"
                :value="service.active"
                @input="e => setService(e, service)"
              />
              <span :class="$style.description">
                {{ service.value }}
              </span>
            </div>
          </div>
          <div>
            <!-- <div
              :class="$style.checkbox"
              v-for="service in additionalServices.slice(3)"
              :key="service.id"
            >
              <Checkbox
                type="primary"
                :value="service.active"
                @input="(e) => setService(e, service)"
              />
              <span :class="$style.description">
                {{ service.value }}
              </span>
            </div> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/basic/Checkbox'
import Select from '@/components/basic/Select'
import constants from '@/application/underOrder'

import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    Checkbox,
    Select
  },
  data() {
    return {
      show: true,
      opened: null,
      activeDeliveryId: 0,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters(['leftExtension', 'rightExtension', 'withoutExtension']),

    doorsType() {
      return constants.DOORS_TYPE
    },
    sideExtansion: {
      get() {
        return constants.SIDE_EXTENSION
      },
      set(value) {
        constants.SIDE_EXTENSION = value
      }
    },
    // material() {
    //   return constants.MATERIAL;
    // },
    hardware() {
      return constants.HARDWARE
    },
    doorsHinges() {
      return constants.DOOR_HINGES
    },
    doorsWidth() {
      return constants.DOOR_WIDTH
    },
    doorsHeight() {
      return constants.DOOR_HEIGHT
    },
    doorsLock() {
      return constants.DOOR_LOCK
    },
    deliveryPrice() {
      return constants.DELIVERY_PRICE
    },
    additionalServices() {
      return constants.ADDITIONAL_SERVICES
    },
    isMobile() {
      return this.windowWidth < 640
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    ...mapMutations(['setOption', 'clearOption']),

    openSection(index) {
      if (this.opened === index) {
        this.opened = null
      } else {
        this.opened = index
      }
    },
    setDeliveryType(e, delivery) {
      if (e) {
        this.activeDeliveryId = delivery.id
        this.setOption(delivery)
      } else {
        this.clearOption(delivery)
      }
    },
    setExtensionType(e, extension) {
      if (e) {
        this.setOption(extension)
      } else {
        this.clearOption(extension)
      }
    },
    setService(e, service) {
      if (e) {
        this.setOption(service)
      } else {
        this.clearOption(service)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.details {
  display: flex;
  flex-direction: column;
  width: 66%;

  .inputs {
    display: flex;
    justify-content: space-between;
    margin: 0.6rem 0 0;

    .section {
      width: 48%;

      .item {
        .text {
          margin: 1rem 0 0.5rem;
          @include smallText();
          font-family: 'Bahnschrift-SemiBold';
          color: $white;
        }

        .select {
          margin: 0 0 1.25rem;

          &:last-child {
            margin: 0 0 0.75rem;
          }
        }
      }
    }
  }

  .subtitle {
    margin: 1rem 0 0.5rem;
    @include subtitleText();
    color: $white;
    font-size: 1rem;

    .arrow {
      display: none;
    }
  }

  .checkboxes {
    display: flex;
    justify-content: space-between;
  }

  .checkbox {
    display: flex;
    margin: 0 0 0.6rem;

    .description {
      margin: 0.25rem 0.25rem 0;
      @include subtext();
      font-size: 0.875rem;
      color: $white;
    }
  }
}

@include for-tablet {
  .details {
    width: 100%;

    .inputs {
      flex-direction: column;

      .section {
        width: 100%;

        .item {
          .select {
            width: 48%;
            &:last-child {
              margin: 0 0 1.25rem;
            }
          }
        }

        .wrapper {
          display: flex;
          justify-content: space-between;

          .item {
            width: 48%;

            .select {
              width: 100%;
            }
          }

          .flex {
            width: 30%;

            .select {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include for-mobile {
  .details {
    margin: 0 0 3rem;

    .inputs {
      margin: 1rem 0 0;
      border-top: 1px solid $transparent-gray-medium;

      .section {
        .item {
          .wrapper {
            padding: 0 0.5rem;
            margin: 0 0 0.5rem;

            .select {
              width: 100%;
            }
          }
        }

        .wrapper {
          flex-direction: column;

          .item {
            width: 100%;

            .select {
              width: 96%;
              margin: 0 auto 1.25rem;
            }
          }

          .flex {
            width: 100%;

            .select {
              width: 100%;
              margin: 0 0 0.75rem;

              &:last-child {
                margin: 0 0 0.75rem;
              }
            }
          }
        }
      }
    }

    .subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 1rem 0.5rem;

      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        background: $white;

        img {
          width: 0.75rem;
          height: 0.75rem;
          transition: all 0.5s ease;
        }

        .active {
          transform: rotate(180deg);
        }
      }
    }

    .item {
      border-bottom: 1px solid $transparent-gray-medium;
    }

    .checkboxes {
      flex-direction: column;
      padding: 0 0.5rem 1rem;
    }
  }
}
</style>
