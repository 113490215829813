<template>
  <section :class="$style.discount">
    <div :class="$style.description">
      <h2 :class="$style.title">YoorDoors подарит Вам скидку на Ваш заказ</h2>
      <h3 :class="$style.subtitle">
        Для получения скидки заполните Ваши данные
      </h3>
    </div>
    <Form :class="$style.form" buttonText="Получить скидку" />
  </section>
</template>

<script>
import Form from '@/components/basic/Form'

export default {
  components: {
    Form
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 87.375rem;
  height: 13.75rem;
  margin: 5rem auto 0;
  padding: 0 5rem;
  background-image: url('../assets/images/discount-bg.png');

  .description {
    width: 50%;

    .title {
      margin: 0 0 0.8rem 0;
      @include bannerTitle();
      letter-spacing: 0.5px;
    }

    .subtitle {
      @include bannerSubtitle();
      letter-spacing: 0.25px;
    }
  }

  .form {
    width: 39.25%;
    height: 53%;
  }
}

@include for-tablet {
  .discount {
    flex-direction: column;
    height: auto;
    margin: 6rem 1rem 0;
    padding: 4rem 5rem 4.5rem;

    .description {
      width: 100%;
      text-align: center;

      .subtitle {
        margin: 0 0 1.5rem 0;
      }
    }

    .form {
      width: 88%;
      height: 7.25rem;
    }
  }
}

@include for-mobile {
  .discount {
    padding: 2.5rem 1rem;
    background-repeat: no-repeat;
    background-size: cover;

    .description {
      .title {
        margin: 0 0 0.5rem 0;
      }

      .subtitle {
        margin: 0 0 0.8rem 0;
      }
    }

    .form {
      width: 100%;
      height: auto;
    }
  }
}
</style>
