<template>
  <section :class="$style.production" id="production">
    <Title :class="$style.title" position="center" weight="bold">
      ПРОИЗВОДСТВО НАШИХ ДВЕРЕЙ
    </Title>
    <div :class="$style.video">
      <div :class="$style.line" />
      <img
        v-if="!startVideo"
        :class="$style.poster"
        :src="require('@/assets/images/video-poster.png')"
        alt="poster"
      />
      <video :class="$style.media" ref="video" @click="paused">
        <source src="@/assets/video/video.mp4" type="video/mp4" />
      </video>
      <transition name="fade">
        <img
          v-if="show"
          :class="$style.your"
          src="@/assets/icons/your.svg"
          alt=""
        />
      </transition>
      <transition name="fade">
        <button v-if="show" :class="$style.play" @click="play">
          <img :class="$style.img" src="@/assets/icons/play.svg" />
        </button>
      </transition>
    </div>
    <div :class="$style.content">
      <div :class="$style.content__wrapper">
        <h4 :class="$style.subtitle">Входные деревянные двери</h4>
        <p :class="$style.text">
          Что совмещает в себе надежность, красоту, стиль и престиж? Конечно же,
          натуральное дерево. Оно отлично вписывается в интерьер и подчеркивает
          эстетику интерьера и экстерьера. Поэтому деревянные входные двери для
          частного дома или квартиры – это лучшее решение.
        </p>
      </div>
      <div :class="$style.content__wrapper">
        <h4 :class="$style.subtitle">Преимущества натурального дерева</h4>
        <p :class="$style.text">
          Чем хороши деревянные входные двери из массива? <br />
          У них есть несколько главных преимуществ: <br />
          1. Они теплые. Даже при сильном морозе дверь не будет холодной. <br />
          2. Обладают отличной звукоизоляцией. <br />
          3. Красивые, добавляют стиля и престижа. <br />
          4. Не поддаются коррозии. <br />
          5. Экологичны.
        </p>
      </div>
    </div>
    <div :class="$style.banner">
      <img :class="$style.img" src="@/assets/images/banner-bg.png" />
      <p :class="$style.text">
        Компания «YourDoors» – производитель межкомнатных и входных дверных
        конструкций. Мы работаем с лучшими современными технологиями. Благодаря
        этому, производится качественная обработка древесины, что гарантирует
        долговечность конструкции. Изготовление входных деревянных дверей на
        заказ для нас основывается на принципах надежности, честности и
        прозрачности. Мы гарантируем, что наши изделия прослужат долго и будут
        полностью обоснованы в цене. У нас можно приобрести деревянные входные
        двери в квартиру недорого вместе с монтажом. Мы сами его выполняем, а
        потому даем гарантию как на сами двери, так и на установку. Работаем при
        этом по договору.
      </p>
      <Button
        :class="$style.button"
        type="fivefold"
        :line="true"
        @click="openPopUpForm"
      >
        УЗНАТЬ ПОДРОБНЕЕ
      </Button>
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'

import { advantages } from '@/application/advantages'
import { mapMutations } from 'vuex'

export default {
  components: {
    Title,
    Button
  },
  data() {
    return {
      show: true,
      startVideo: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    advantagesData() {
      return advantages[0]
    }
  },
  methods: {
    ...mapMutations(['changeFormStatus']),

    play() {
      this.startVideo = true
      this.show = false
      this.$refs.video.play()
    },
    paused() {
      this.show = true
      this.$refs.video.pause()
    },
    openPopUpForm() {
      this.changeFormStatus(true)
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.production {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 89.375rem;
  padding: 0 1rem;
  margin: 0 auto;

  .title {
    margin: 5.5rem 0 3.5rem;
  }

  .video {
    position: relative;
    width: 83%;

    .line {
      &::before,
      &::after {
        position: absolute;
        left: -1%;
        content: '';
        width: 102%;
        height: 8%;
        background: $white;
      }

      &::before {
        top: -1%;
      }

      &::after {
        bottom: -1%;
      }
    }

    .poster {
      position: absolute;
      top: 6.5%;
      left: 0;
      width: 100%;
      height: 87%;
    }

    .media {
      width: 100%;
      height: auto;
    }

    .your {
      position: absolute;
      top: 30%;
      left: 20%;
      pointer-events: none;
    }

    .play {
      position: absolute;
      top: 41.5%;
      left: 46.5%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      border: none;
      background: $dark-green;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 83%;
    margin: 0 0 3rem;

    &__wrapper {
      width: 47%;
      padding: 1rem;
      background: $bg-gray;
    }
    .subtitle {
      @include subtitleText();
      margin: 1.3rem 0 0.5rem;
      text-align: center;
    }

    .text {
      @include text();
      line-height: 1.5rem;
    }
  }

  .banner {
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 4.1rem 9.5rem;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    .text {
      @include link();
      color: $white;
      line-height: 190%;
      letter-spacing: 0.15px;
    }

    .button {
      display: none;
    }
  }
}

@include for-tablet {
  .production {
    .title {
      margin: 3.5rem 0 3.2rem;
    }

    .video {
      width: 100%;

      .your {
        width: 58%;
      }

      .play {
        top: 39%;
        left: 45.75%;
        width: 3.75rem;
        height: 3.75rem;

        .img {
          width: 55%;
        }
      }
    }

    .content {
      width: 100%;
    }

    .banner {
      padding: 4.5rem 3rem;
      text-align: center;

      .text {
        font-size: 0.875rem;
      }
    }
  }
}

@include for-mobile {
  .production {
    padding: 0;

    .title {
      margin: 3rem 0 2rem;
    }

    .video {
      .your {
        top: 32%;
        left: 7%;
        width: 85%;
      }

      .play {
        left: 40.5%;
      }
    }

    .content {
      flex-direction: column;
      align-items: center;
      margin: 0 0 1rem;

      &__wrapper {
        width: 90%;
        margin: 1.5rem 0;
      }
    }

    .banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      padding: 2.5rem 1rem;

      .text {
        margin: 0 0 1.25rem;
        letter-spacing: 0;
      }

      .button {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
