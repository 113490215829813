<template>
  <header :class="$style.header">
    <div :class="$style.wrapper">
      <img
        v-if="!showMobileMenu"
        :class="$style.burger"
        src="@/assets/icons/burger-menu.svg"
        @click="openMobileMenu"
      />
      <img :class="$style.logo" src="@/assets/icons/logo.svg" alt="logo" />
      <nav :class="$style.nav">
        <p :class="$style.link" v-scroll-to="'#inStock'">Двери в наличии</p>
        <p :class="$style.link" v-scroll-to="'#underOrder'">
          Индивидуальный заказ
        </p>
        <p :class="$style.link" v-scroll-to="'#production'">Производство</p>
        <p :class="$style.link" v-scroll-to="'#ourWorks'">Наши работы</p>
        <p :class="$style.link" @click="changeFormStatus(true)">
          Обратный звонок
        </p>
      </nav>
      <div :class="$style.wrap">
        <div :class="$style.locations">
          <span :class="$style.location">Я из:</span>
          <span
            :class="[
              $style.location,
              { [$style.location_active]: location === 'BY' }
            ]"
            @click="changeCountry('BY')"
          >
            <img
              :class="$style.location__icon"
              src="@/assets/icons/bel-flag.svg"
            />
            <span :class="$style.location__name"> Беларуси </span>
          </span>
          <span
            :class="[
              $style.location,
              { [$style.location_active]: location === 'RUS' }
            ]"
            @click="changeCountry('RUS')"
          >
            <img
              :class="$style.location__icon"
              src="@/assets/icons/rus-flag.svg"
            />
            <span :class="$style.location__name"> России </span>
          </span>
        </div>
        <ul :class="$style.phone">
          <li><img src="@/assets/icons/phone.svg" /></li>
          <li :class="$style.number">
            <a href="tel:+375296993535">+375 29 699 35 35</a>
          </li>
          <li
            :class="$style.icon"
            @click="goToSocial('https://www.instagram.com/doorsyours/')"
          >
            <img src="@/assets/icons/instagram.svg" />
          </li>
          <li
            :class="$style.icon"
            @click="
              goToSocial(
                'https://www.youtube.com/channel/UCYfAvXPytnqo9cPjlqBz6YA'
              )
            "
          >
            <img src="@/assets/icons/youtube.svg" />
          </li>
        </ul>
      </div>
    </div>
    <div :class="$style.tablet">
      <p :class="$style.link" v-scroll-to="'#inStock'">Двери в наличии</p>
      <span :class="$style.line"></span>
      <p :class="$style.link" v-scroll-to="'#underOrder'">
        Индивидуальный заказ
      </p>
      <span :class="$style.line"></span>
      <p :class="$style.link" v-scroll-to="'#production'">Производство</p>
      <span :class="$style.line"></span>
      <p :class="$style.link" v-scroll-to="'#ourWorks'">Наши работы</p>
      <span :class="$style.line"></span>
      <p :class="$style.link" @click="changeFormStatus(true)">
        Обратный звонок
      </p>
    </div>
    <transition name="topExit">
      <div v-if="showMobileMenu" :class="$style.mobileMenu">
        <div :class="$style.header">
          <div :class="$style.wrapper">
            <img
              :class="$style.burger"
              src="@/assets/icons/burger-menu-close.svg"
              @click="openMobileMenu"
            />
            <img
              :class="$style.logo"
              src="@/assets/icons/logo-white.svg"
              alt="logo"
            />
          </div>
        </div>
        <nav :class="$style.links">
          <p :class="$style.link" @click="goTo('#inStock')">Двери в наличии</p>
          <p :class="$style.link" @click="goTo('#underOrder')">
            Индивидуальный заказ
          </p>
          <p :class="$style.link" @click="goTo('#production')">Производство</p>
          <p :class="$style.link" @click="goTo('#ourWorks')">Наши работы</p>
        </nav>
        <div :class="$style.contacts">
          <div :class="$style.section">
            <h5 :class="$style.title">Производство:</h5>
            <div :class="$style.text">
              <img :class="$style.icon" src="@/assets/icons/phone-number.svg" />
              <span :class="$style.phone">
                <a href="tel:+375296569238">+375 29 656 92 38</a>
                <a href="tel:+375296993535">+375 29 699 35 35</a>
              </span>
            </div>
            <p :class="$style.text">
              <img :class="$style.icon" src="@/assets/icons/address.svg" />
              247511, Гомельская обл., д. Борщевка 10
            </p>
          </div>
          <div :class="$style.section">
            <h5 :class="$style.title">Отдел продаж:</h5>
            <div :class="$style.text">
              <img :class="$style.icon" src="@/assets/icons/phone-number.svg" />
              <span :class="$style.phone">
                <a href="tel:+375296569238">+375 29 656 92 38</a>
                <a href="tel:+375296993535">+375 29 699 35 35</a>
              </span>
            </div>
            <p :class="$style.text">
              <img :class="$style.icon" src="@/assets/icons/address.svg" />
              246000, г. Гомель 50 лет БССР д.7 оф.6
            </p>
            <p :class="[$style.text, $style.email]">
              <img :class="$style.icon" src="@/assets/icons/mail.svg" />
              <a href="mailto:doorsyour@gmail.com">
                e-mail to: doorsyour@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div :class="$style.buttons">
          <Button type="fivefold" :call="true" @click="openPopUpForm">
            ЗАКАЗАТЬ ЗВОНОК
          </Button>
          <Button
            type="fivefold"
            :instagram="true"
            @click="goToSocial('https://www.instagram.com/doorsyours/')"
          >
            МЫ В INSTAGRAM
          </Button>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
import Button from '@/components/basic/Button'

import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    Button
  },
  data() {
    return {
      showMobileMenu: false
    }
  },
  computed: {
    ...mapGetters(['location'])
  },
  methods: {
    ...mapMutations(['changeFormStatus', 'changeLocation']),

    goTo(path) {
      this.closeMobileMenu()
      this.$scrollTo(path)
    },

    goToSocial(link) {
      location.href = link
    },

    openPopUpForm() {
      this.closeMobileMenu()
      this.changeFormStatus(true)
    },

    openMobileMenu() {
      if (this.showMobileMenu) {
        this.closeMobileMenu()
      } else {
        this.showMobileMenu = true
        document.body.style.overflow = 'hidden'
      }
    },

    closeMobileMenu() {
      this.showMobileMenu = false
      document.body.style.overflow = 'visible'
    },

    changeCountry(country) {
      if (country === this.location) {
        return
      } else {
        this.changeLocation(country)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.header {
  max-width: 89.375rem;
  margin: 2.25rem auto;
  padding: 0 1rem;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    .logo {
      width: 16.5%;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 55%;

      .link {
        @include link();
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $black;
          transform: translateX(0.25rem);
        }

        &:last-child {
          position: relative;
          padding: 0.5rem;
          color: $white;
          background: $ligth-green;
        }
      }
    }

    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 22%;
      height: 5rem;

      .locations {
        display: flex;

        .location {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0.2rem 0 0;
          @include link();
          transition: all 0.2s ease;

          &:nth-child(n + 2) {
            padding: 0;
            margin: 0 0 0 1rem;
            @include text();
            cursor: pointer;
          }

          &_active {
            font-weight: 700;
            .location {
              &__icon {
                filter: grayscale(0%);
              }
            }
          }

          &__icon {
            margin: 0 0.5rem 0 0;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            filter: grayscale(90%);
            transition: all 0.2s ease;
          }

          &__name {
            padding: 0.3rem 0 0;
          }
        }
      }
    }

    .phone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0;

      li {
        list-style: none;
      }

      .number {
        @include subtitleText();
        font-weight: bold;

        a {
          color: $brown;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            color: $green;
          }
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        margin: 0 0 0.3rem;
        background: $brown;
        cursor: pointer;

        &:hover {
          img {
            filter: invert(25%);
            transition: all 0.2s ease-in-out;
          }
        }

        &:nth-child(4) {
          background: $ligth-green;
        }

        &:last-child {
          padding: 0.2rem;
        }
      }
    }

    .burger {
      display: none;
    }
  }

  .tablet {
    display: none;
    justify-content: space-evenly;
    padding: 0.75rem 0;
    background: $ligth-green;

    .link {
      @include subtext();
      font-size: 0.8rem;
      color: $white;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $dark-brown;
        transform: translateX(0.25rem);
      }

      &:last-child {
        color: $dark-green;
        font-weight: bold;
      }
    }

    .line {
      opacity: 0.3;
      border-right: 1px solid $white;
    }
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding: 0 0 2rem;
    background: $transparent-green;
    overflow: scroll;

    .header {
      .wrapper {
        .burger {
          width: 8%;
        }
      }
    }

    .links {
      padding: 0 2rem;

      .link {
        padding: 1.25rem 0;
        border-bottom: 1px solid $transparent-gray-medium;
        @include link();
        color: $white;
        text-transform: uppercase;

        &:last-child {
          border: none;
        }
      }
    }

    .contacts {
      padding: 0 2rem;

      .section {
        margin: 2rem 0;
        color: $white;

        .title {
          margin: 0 0 0.25rem;
          @include subtitleText();
        }

        .text {
          display: flex;
          align-items: flex-start;
          @include subtext();

          &:last-child {
            align-items: center;
          }

          .icon {
            margin: 0.15rem 0.5rem 0 0;
          }

          .phone {
            display: flex;
            flex-direction: column;
          }

          a {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 2rem;
      height: 6.5rem;
    }
  }
}

@include for-tablet {
  .header {
    padding: 0;
    margin: 1.25rem auto 0;

    .wrapper {
      padding: 0 1rem 1.75rem 1.75rem;

      .logo {
        width: 30%;
      }

      .nav {
        display: none;
      }

      .wrap {
        width: 70%;

        .locations {
          justify-content: flex-end;
        }
      }

      .phone {
        display: flex;
        justify-content: flex-end;
        padding: 0;

        li,
        .icon {
          margin: 0 0 0 1rem;
        }
      }
    }

    .tablet {
      display: flex;
    }
  }
}

@include for-mobile {
  .header {
    .wrapper {
      position: relative;
      justify-content: center;

      .burger {
        position: absolute;
        left: 5%;
        top: 20%;
        display: block;
        width: 10%;
      }

      .logo {
        width: 12rem;
      }

      .wrap {
        display: none;
      }
    }

    .tablet {
      display: none;
    }
  }
}
</style>
