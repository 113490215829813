<template>
  <div :class="$style.advantagesSlider">
    <img
      :class="$style.image"
      :src="require(`@/assets/images/advantages/${advantages.slide}.jpg`)"
      alt="slide"
    />
    <div :class="$style.content">
      <Title :class="$style.title" color="green" weight="light">
        Входные двери от YourDoors это:
      </Title>
      <div :class="$style.flex">
        <div :class="$style.advantages">
          <div
            :class="$style.advantage"
            v-for="(advantage, index) in advantages.items"
            :key="index"
          >
            <img
              :class="$style.icon"
              :src="require(`@/assets/icons/${advantage.image}.svg`)"
              alt="advantage"
            />
            <span :class="$style.wrapper">
              <h4 :class="$style.subtitle">{{ advantage.title }}</h4>
              <p :class="$style.text">
                {{ advantage.text }}
              </p>
            </span>
          </div>
        </div>
        <div :class="$style.banner">
          <p :class="$style.text">ВХОДНЫЕ И МЕЖКОМНАТНЫЕ</p>
          <p :class="$style.text">ДВЕРИ В НАЛИЧИИ И</p>
          <p :class="$style.text">ПОД ЗАКАЗ</p>
          <p :class="$style.text">ПРЕМИАЛЬНОГО КЛАССА</p>
        </div>
      </div>
    </div>
    <img :class="$style.logo" src="@/assets/icons/your.svg" alt="your" />
  </div>
</template>

<script>
import Title from '@/components/basic/Title'

export default {
  components: {
    Title
  },

  props: {
    advantages: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.advantagesSlider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .content {
    max-width: 89.375rem;
    height: 100%;
    margin: 0 auto;
    padding: 4.75rem 1rem 6rem;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .advantages {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;
        height: 100%;

        .advantage {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .wrapper {
            width: 88%;

            .subtitle {
              @include subtitleText();
              margin: 0.2rem 0;
            }

            .text {
              width: 80%;
              @include text();
            }
          }
        }
      }

      .banner {
        width: 40%;
        margin: 0 0 3.5rem 0;

        .flex {
          display: flex;
        }

        .text {
          width: 26.5rem;
          padding: 0.35rem;
          margin: 0 0 0.25rem 0;
          background: $light-brown;
          @include slider();

          &:nth-child(2) {
            width: 19rem;
          }

          &:nth-child(3) {
            width: 11rem;
          }

          &:last-child {
            width: 22.5rem;
          }
        }
      }
    }
  }

  .logo {
    position: absolute;
    left: 2%;
    bottom: 7%;
    z-index: -1;
    opacity: 0;
  }
}

@include for-tablet {
  .advantagesSlider {
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1),
      rgba(0, 0, 0, 0) 80%
    );

    .image {
      min-width: 1920px;
      right: -15rem;
    }

    .content {
      padding: 4.75rem 0 6rem 2.75rem;

      .title {
        width: 50%;
        letter-spacing: 2.5px;
      }

      .flex {
        height: 70%;

        .advantages {
          .advantage {
            align-items: center;
            justify-content: flex-start;

            .icon {
              margin: 0 1.85rem 0 0;
            }

            .wrapper {
              width: 65%;

              .text {
                display: none;
              }
            }
          }
        }

        .banner {
          width: 59%;
          margin: 0;

          .text {
            width: 22.5rem;
            font-size: 1.483rem;

            &:nth-child(2) {
              width: 16rem;
            }

            &:nth-child(3) {
              width: 9rem;
            }

            &:last-child {
              width: 19.5rem;
            }
          }
        }
      }
    }

    .logo {
      width: 55%;
      left: 4%;
      bottom: 12%;
      opacity: 1;
    }
  }
}

@include for-mobile {
  .advantagesSlider {
    .image {
      right: -19.5rem;
    }

    .content {
      padding: 2.8rem 1.5rem;

      .title {
        width: 100%;
        margin: 0 0 1.25rem;
        text-align: center;
        font-size: 1.75rem;
        letter-spacing: 0.5px;
      }

      .flex {
        flex-direction: column;
        height: 100%;

        .advantages {
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          height: 45%;

          .advantage {
            flex-direction: column;
            align-items: flex-start;
            width: 7.5rem;

            .wrapper {
              .subtitle {
                font-size: 0.875rem;
              }
            }

            .icon {
              width: 40%;
              height: 40%;
              margin: 0 0 0.5rem 0;
            }
          }
        }

        .banner {
          width: 100%;
          height: 54%;

          .text {
            width: 16rem;
            font-size: 1.063rem;
            letter-spacing: 0;

            &:nth-child(2) {
              width: 11.5rem;
            }

            &:nth-child(3) {
              width: 7rem;
            }

            &:last-child {
              width: 14rem;
            }
          }
        }
      }
    }

    .logo {
      width: 85%;
      left: 6%;
      bottom: 10%;
    }
  }
}
</style>
