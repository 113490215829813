export default {
  state: {
    feedbacks: [],
    activeFeedback: {}
  },

  mutations: {
    setActiveFeedback(state, feedback) {
      state.activeFeedback = feedback
    },

    setFeedbacks(state, feedbacks) {
      state.feedbacks = feedbacks
    }
  },

  getters: {
    allFeedbacks(state) {
      return state.feedbacks
    },

    activeFeedback(state) {
      return state.activeFeedback
    }
  }
}
