<template>
  <section :class="$style.advantages" id="advantages">
    <VueSlickCarousel
      :class="$style.carousel"
      v-bind="settings"
      ref="advantagesCarousel"
    >
      <div
        :class="$style.slider"
        v-for="advantages in advantagesData"
        :key="advantages.id"
      >
        <Slider :advantages="advantages" />
      </div>
    </VueSlickCarousel>
    <Button :class="[$style.arrow, $style.arrow__next]" @click="showNext">
      <img src="@/assets/icons/arrow.svg" />
    </Button>
    <Button :class="[$style.arrow, $style.arrow__prev]" @click="showPrev">
      <img src="@/assets/icons/arrow.svg" />
    </Button>
  </section>
</template>

<script>
import Slider from '@/components/common/Advantage'
import Button from '@/components/basic/Button'
import { advantages } from '@/application/advantages'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    Slider,
    Button
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        draggable: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              vertical: false,
              draggable: true
            }
          }
        ]
      }
    }
  },
  computed: {
    advantagesData() {
      return advantages
    }
  },
  methods: {
    showNext() {
      this.$refs.advantagesCarousel.next()
    },
    showPrev() {
      this.$refs.advantagesCarousel.prev()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.custom-dot-class {
  right: -7rem;
  top: 45.5%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 19rem;
  height: 4rem;
  background: $white;
  opacity: 0.9;
  transform: rotate(90deg);
}

.slick-active {
  button {
    &::before {
      margin: 0.45rem -0.45rem;
      content: '' !important;
      height: 5px !important;
      width: 31px !important;
      background: $black;
      border-radius: 2.5px;
    }
  }
}

@include for-tablet {
  .custom-dot-class {
    top: 82.5%;
    right: 29%;
    width: 40%;
    transform: rotate(0);
  }
}

@include for-mobile {
  .custom-dot-class {
    top: 90%;
    right: 5%;
    width: 90%;
    height: 3rem;
  }
}
</style>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.advantages {
  position: relative;
  width: 100%;

  .carousel {
    width: 100%;

    .slider {
      outline: none;
      height: 38.75rem;
    }
  }

  .arrow {
    position: absolute;
    right: 1.9rem;
    z-index: 5;
    background: transparent;

    &__next {
      top: 30%;
    }

    &__prev {
      top: 65%;
      transform: rotate(180deg);
    }
  }
}

@include for-tablet {
  .advantages {
    .arrow {
      top: 84.75%;

      &__next {
        right: 34%;
        transform: rotate(90deg);
      }

      &__prev {
        right: 62%;
        transform: rotate(270deg);
      }
    }
  }
}

@include for-mobile {
  .advantages {
    .arrow {
      top: 91%;

      &__next {
        right: 12%;
      }

      &__prev {
        right: 80%;
      }
    }
  }
}
</style>
