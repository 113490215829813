import axios from 'axios'

export default {
  state: {
    door: null,
    width: null,
    height: null,
    type: null,
    extension: null,
    doorCloser: null,
    nightCastle: null,
    hinges: null,
    lock: null,
    delivery: null,
    installing: null,
    preparation: null
  },

  mutations: {
    setDoor(state, door) {
      state.door = door
    },
    setOption(state, option) {
      state[option.name] = {
        value: option.value,
        price: option.price,
        name: option.name
      }
    },
    clearOption(state, option) {
      state[option.name] = null
    },
    resetCalculator(state) {
      for (let key in state) {
        state[key] = null
      }
    }
  },

  getters: {
    totalPrice(state) {
      let price = 0
      Object.values(state).map(e => {
        if (e && typeof e.price === 'number') {
          price += e.price
        }
      })
      return price
    },
    calculator(state) {
      return state
    }
  },

  actions: {
    postCalculatorForm({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://api.ydoors.by', {
            name: `Имя: ${payload.name}, телефон: ${payload.phone}`,
            message: `Цена: ${payload.price}; ${Object.values(state).map(
              elem => {
                if (elem && elem.id) {
                  return `Дверь: '${elem.name}'; `
                } else if (elem && elem.name === 'width') {
                  return `Высота: (${elem.value.substr(0, 8)}); `
                } else if (elem && elem.name === 'height') {
                  return `Ширина: (${elem.value.substr(0, 8)}); `
                } else if (elem) {
                  return `${elem.value}; `
                } else {
                  return
                }
              }
            )}`
              .replace(/,/g, '')
              .slice(0, -1)
          })
          .then(response => {
            console.log(response)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject()
          })
      })
    }
  }
}
