import Vue from 'vue'
import App from './App.vue'
import store from './store'
import '@/assets/styles/common.scss'

import VueScrollTo from 'vue-scrollto'
import VueSlickCarousel from 'vue-slick-carousel'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

Vue.config.productionTip = false

Vue.component(VueSlickCarousel.name, VueSlickCarousel)
Vue.use(VueScrollTo, VueAwesomeSwiper)

Vue.use(VueYandexMetrika, {
  id: 86129566,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
