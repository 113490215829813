<template>
  <div :class="$style.customSelect">
    <div :class="$style.select" @click="show = true">
      <span v-if="selected">{{ selected.value }}</span>
      <span v-if="!selected && value">{{ value.value }}</span>
      <span v-if="!selected && placeholder">{{ placeholder }}</span>
    </div>
    <transition name="fade">
      <div v-if="show">
        <div :class="$style.closeDropdown" @click="show = false"></div>
        <div v-if="show" :class="$style.dropdown">
          <div
            :class="[
              $style.option,
              { [$style.selected]: selected ? item.id === selected.id : false }
            ]"
            v-for="item in items"
            :key="item.id"
            @click="setValue(item)"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      selected: null
    }
  },
  methods: {
    setValue(item) {
      this.show = false
      this.selected = item
      this.$emit('input', item)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts/mixins.scss';

.customSelect {
  position: relative;
  width: 100%;
  border: 1px solid $transparent-gray-medium;
  @include smallText();
  color: $light-gray;
  cursor: pointer;

  .select {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.5rem 0.4rem 1rem;

    span {
      flex: 1;
    }

    &:after {
      content: '';
      display: block;
      height: 24px;
      width: 24px;
      background-image: url('../../assets/icons/select-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .closeDropdown {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    cursor: default;
  }

  .dropdown {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 3rem;
    color: $footer-gray;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    .option {
      padding: 1rem;
      border-bottom: 1px solid $light-gray;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $light-gray;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .selected {
      color: $dark-gray;
    }
  }
}
</style>
