<template>
  <div :class="$style.ourWorksSlider">
    <div :class="$style.image">
      <div :class="$style.fullscreen">
        <img :class="$style.icon" src="@/assets/icons/fullscreen.svg" />
      </div>
      <div :class="$style.wrapper">
        <img
          :class="$style.img"
          :src="require(`@/assets/images/door/${door.image}.jpg`)"
        />
      </div>
    </div>
    <div :class="$style.description">
      <div :class="$style.section">
        <p :class="$style.subtitle">Модель {{ door.model }}</p>
      </div>
      <div :class="$style.section">
        <p :class="$style.subtitle">Описание</p>
        <p :class="$style.text">
          {{ door.description }}
        </p>
      </div>
      <div :class="$style.flex">
        <div :class="$style.section">
          <div :class="$style.item">
            <img :class="$style.item__icon" src="@/assets/icons/calendar.svg" />
            <div :class="$style.wrapper">
              <p :class="$style.subtitle">Срок изготовления</p>
              <p :class="$style.text">{{ door.time }}</p>
            </div>
          </div>
          <div :class="$style.item">
            <img :class="$style.item__icon" src="@/assets/icons/point.svg" />
            <div :class="$style.wrapper">
              <p :class="$style.subtitle">Место</p>
              <p :class="$style.text">{{ door.location }}</p>
            </div>
          </div>
        </div>
        <div :class="$style.section">
          <div :class="$style.item">
            <img :class="$style.item__icon" src="@/assets/icons/material.svg" />
            <div :class="$style.wrapper">
              <p :class="$style.subtitle">Материал</p>
              <p :class="$style.text">{{ door.material }}</p>
            </div>
          </div>
          <div :class="[$style.item, { [$style.item_disabled]: !door.size }]">
            <img :class="$style.item__icon" src="@/assets/icons/size.svg" />
            <div :class="$style.wrapper">
              <p :class="$style.subtitle">Размер</p>
              <p :class="$style.text">{{ door.size }}</p>
            </div>
          </div>
        </div>
      </div>
      <div :class="[$style.price, { [$style.price_disabled]: !door.price }]">
        <p :class="$style.subtitle">Цена</p>
        <p :class="$style.text">{{ price(door.price) }}</p>
      </div>
      <div :class="$style.detail">
        <p :class="$style.subtitle">Детальное описание</p>
        <p :class="$style.text">
          {{ door.detailed }}
        </p>
      </div>
      <Button v-if="door.feedbackId" :class="$style.button" type="tertiary">
        СМОТРЕТЬ ОТЗЫВ
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/basic/Button'

import { converter } from '@/application/converter'

import { mapGetters } from 'vuex'

export default {
  components: {
    Button
  },

  props: {
    door: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters(['location'])
  },

  methods: {
    price(num) {
      if (this.location === 'BY') {
        return `${this.priceSpase(num)} бел. руб`
      } else {
        return `${this.priceSpase(num * converter.value)} рос. руб.`
      }
    },

    priceSpase(price) {
      return String(price).length > 3
        ? String(price)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
            .replace(/\.[^.]+$/, '')
        : price
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.ourWorksSlider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image {
    position: relative;
    width: 43%;
    height: 100%;
    transform: scale(1);
    cursor: pointer;
    transition: all 0.5s ease;

    .fullscreen {
      position: absolute;
      top: 4%;
      right: 4%;
      display: none;
      align-items: center;
      justify-content: center;
      width: 2.875rem;
      height: 2.875rem;
      background: $light-brown;
      cursor: pointer;
    }

    .wrapper {
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 54%;
    height: 99%;
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.5s ease, transform 0.5s ease;
    overflow: hidden;

    .subtitle {
      @include subtitleText();
    }

    .text {
      @include text();
    }

    .flex {
      display: flex;
      justify-content: space-between;

      .section {
        display: flex;
        flex-direction: column;
        width: 48%;

        .item {
          display: flex;
          align-items: flex-start;
          height: 3.5rem;
          margin: 0 0 1rem;

          &_disabled {
            visibility: hidden;
          }

          &__icon {
            width: 1.5rem;
            height: 1.5rem;
          }

          .wrapper {
            margin: 0 0 0 0.75rem;
          }
        }
      }
    }

    .section {
      border-bottom: 1px solid $light-gray;
    }

    .price {
      &_disabled {
        visibility: hidden;
      }

      .text {
        font-size: 1.438rem;
        color: $ligth-green;
      }
    }

    .detail {
      width: 100%;

      .text {
        width: 99%;
        line-height: 160%;
        letter-spacing: 0.15px;
      }
    }

    .button {
      background: transparent;
    }
  }
}

@include for-tablet {
  .ourWorksSlider {
    position: relative;
    flex-direction: column;
    overflow: visible;

    .image {
      width: 18rem;
    }

    .description {
      position: absolute;
      top: 34rem;

      .flex {
        height: 24%;

        .section {
          justify-content: space-between;
          width: 46%;
          margin: 0.5rem 0 0;
          text-align: start;

          .item {
            margin: 0;
          }
        }
      }

      .section {
        text-align: center;
      }

      .price {
        width: 96%;
        padding: 0.5rem 0 0.15rem;
        background: $ligth-green;
        color: $white;
        text-align: center;

        .text {
          color: $white;
        }
      }

      .detail {
        text-align: center;

        .text {
          width: 96%;
        }
      }

      .button {
        width: 96%;
      }
    }
  }
}

@include for-mobile {
  .ourWorksSlider {
    .image {
      width: 100%;
      height: 56%;

      .fullscreen {
        width: 2rem;
        height: 2rem;

        .icon {
          width: 60%;
        }
      }
    }

    .description {
      justify-content: flex-start;
      left: auto;
      right: auto;
      top: 20rem;

      .flex {
        flex-direction: column;
        height: auto;

        .section {
          width: 100%;
          border: none;
          padding: 0;
          margin: 0;

          .item {
            padding: 0.75rem 1rem 0.5rem;
            border-bottom: 1px solid $light-gray;

            &:nth-child(n + 2) {
              padding: 0.5rem 1rem;
            }
          }
        }
      }

      .section {
        padding: 0 1rem 0.75rem;
      }

      .price {
        width: 100%;
        margin: 1.15rem 0 1.25rem;
      }

      .detail {
        display: none;
      }

      .button {
        width: 100%;
      }
    }
  }
}
</style>
