export default {
  state: {
    images: [],
    statusGallery: false
  },

  mutations: {
    setImages(state, images) {
      state.images = images
    },

    changeStatusGallery(state, status) {
      state.statusGallery = status
    }
  },

  getters: {
    galleryImages(state) {
      return state.images
    },

    statusGallery(state) {
      return state.statusGallery
    }
  }
}
