<template>
  <button
    :class="[$style.button, $style[type], $style[color], $style[disabled]]"
    @click="$emit('click')"
  >
    <i v-if="line" :class="[$style.line, { [$style.brown]: lineBrown }]"></i>
    <img
      v-if="arrow"
      :class="[$style.arrow, { [$style.arrow__left]: arrowLeft }]"
      src="@/assets/icons/arrow.svg"
    />
    <img v-if="call" :class="$style.icon" src="@/assets/icons/call.svg" />
    <img
      v-if="instagram"
      :class="$style.icon"
      src="@/assets/icons/instagram.svg"
    />
    <slot />
    <i v-if="line" :class="[$style.line, { [$style.brown]: lineBrown }]"></i>
    <img
      v-if="arrow"
      :class="[$style.arrow, { [$style.arrow__left]: arrowLeft }]"
      src="@/assets/icons/arrow.svg"
    />
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    color: String,
    line: Boolean,
    lineBrown: Boolean,
    arrow: Boolean,
    disabled: String,
    call: Boolean,
    instagram: Boolean,
    arrowLeft: Boolean
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/fonts/mixins.scss';
@import '@/assets/styles/colors.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  outline: none;
  border: none;
  @include smallText();
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &::before {
    display: none;
  }

  &.primary {
    background: $light-brown;
  }

  &.secondary {
    background: $brown;
    padding: 0.85rem 0.4rem;
  }

  &.tertiary {
    background: $white;
    border: 1px solid $brown;
  }

  &.quaternary {
    background: $transparent-gray;
    padding: 0.85rem 0.4rem;
  }

  &.fivefold {
    background: transparent;
    border: 1px solid $white;
    color: $white;
  }

  &.popup {
    color: $white;
    background: $line;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.brown {
    color: $dark-brown;
  }

  .line {
    width: 1.875rem;
    height: 0.063rem;
    margin: 0 1rem;
    background: $white;

    &.brown {
      background: $dark-brown;
    }
  }

  .arrow {
    transform: rotate(90deg) translateY(1.25rem);

    &:last-child {
      transform: rotate(90deg) translateY(-1.25rem);
    }

    &__left {
      transform: rotate(-90deg) translateY(-1.25rem);

      &:last-child {
        transform: rotate(-90deg) translateY(1.25rem);
      }
    }
  }

  .icon {
    margin: 0 0.5rem 0 0;
  }
}
</style>
