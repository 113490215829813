<template>
  <div :class="$style.inStockSlider">
    <div :class="$style.images">
      <div :class="$style.image">
        <img
          :class="$style.img"
          :src="require(`@/assets/images/door/${image}.jpg`)"
        />
      </div>
      <div :class="$style.wrapper">
        <div
          :class="[$style.line, { [$style.active]: image === img.path }]"
          v-for="(img, index) in door.images"
          :key="index"
          @click="changeImg(img)"
        >
          <img
            :class="$style.img"
            :src="require(`@/assets/images/door/${img.path}-small.jpg`)"
          />
        </div>
      </div>
    </div>
    <div :class="$style.description">
      <div :class="$style.section">
        <p :class="$style.subtitle">Модель</p>
        <p :class="$style.text">{{ door.model }}</p>
      </div>
      <div :class="$style.section">
        <p :class="$style.subtitle">Описание</p>
        <p :class="$style.text">{{ door.description }}</p>
      </div>
      <div :class="$style.section">
        <div :class="$style.wrapper">
          <p :class="$style.subtitle">Материал</p>
          <p :class="$style.text">{{ door.material }}</p>
        </div>
        <div :class="$style.wrapper">
          <p :class="$style.subtitle">Размер</p>
          <p :class="$style.text">{{ door.size }}</p>
        </div>
        <div :class="$style.wrapper">
          <p :class="$style.subtitle">Толщина полотна</p>
          <p :class="$style.text">{{ door.thickness }}</p>
        </div>
      </div>
      <div :class="$style.section">
        <p :class="$style.subtitle">Возможности</p>
        <p :class="$style.text">{{ door.possibilities }}</p>
      </div>
      <div :class="$style.detail">
        <p :class="$style.subtitle">Детальное описание</p>
        <p :class="$style.text">
          {{ door.detailed }}
        </p>
      </div>
      <Button
        :class="$style.button"
        type="tertiary"
        :line="true"
        :lineBrown="true"
        @click="openPopUpForm"
      >
        УЗНАТЬ ПОДРОБНЕЕ
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/basic/Button'

import { mapMutations } from 'vuex'

export default {
  components: {
    Button
  },
  props: {
    door: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      image: this.door.image
    }
  },
  methods: {
    ...mapMutations(['changeFormStatus']),

    changeImg(img) {
      this.image = img.path
    },
    openPopUpForm() {
      this.changeFormStatus(true)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.inStockSlider {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 1rem;

  .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 36%;
    height: 100%;
    margin: 0 0 0 3%;
    background: $medium-gray;

    .image {
      width: 10rem;
      height: 61%;
      margin: 3.35rem 0 0;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-around;
      width: 40%;
      height: 11%;
      margin: 2.75rem 0 0;

      .line {
        position: relative;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.2s ease-in-out;

        &::before {
          position: absolute;
          left: -1rem;
          bottom: 0;
          content: '';
          width: 3.5rem;
          height: 0.125rem;
          background: $dark-green;
        }

        .img {
          width: 1.438rem;
          height: 2.75rem;
        }
      }

      .active {
        opacity: 1;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 51%;
    height: 100%;
    margin: 0 3% 0 0;

    .subtitle {
      @include subtitleText();
    }

    .text {
      @include text();
    }

    .section {
      padding: 0 0 1rem;
      border-bottom: 1px solid $light-gray;

      &:nth-child(n + 2) {
        padding: 1rem 0;
      }

      &:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .wrapper {
          width: 35%;
        }
      }

      &:nth-child(4) {
        margin: 0 0 1rem;
      }
    }

    .detail {
      width: 100%;
      margin: 0 0 1.7rem;

      .text {
        line-height: 160%;
        letter-spacing: 0.15px;
      }
    }
  }
}

@include for-tablet {
  .inStockSlider {
    position: relative;
    padding: 0;

    .images {
      width: 48%;
      height: 65%;
      margin: 0;

      .image {
        height: 64%;
        margin: 2.25rem 0 0;
      }

      .wrapper {
        height: 12%;
        margin: 2rem 0 0;

        .line {
          &::before {
            width: 2.5rem;
            left: -0.75rem;
          }
          .img {
            width: 1.1rem;
            height: 2rem;
          }
        }
      }
    }

    .description {
      width: 45%;
      padding: 0 0 4rem;

      .subtitle {
        margin: 0 0 0.15rem 0;
      }

      .detail {
        margin: 0;
      }

      .button {
        position: absolute;
        left: 6.25%;
        bottom: 0.75rem;
        width: 87%;
      }
    }
  }
}

@include for-mobile {
  .inStockSlider {
    flex-direction: column;
    padding: 0 0.5rem;

    .images {
      width: 100%;
      height: 55%;

      .image {
        height: 65%;
        margin: 2.1rem 0 0;
      }

      .wrapper {
        height: 10%;
        margin: 0;

        .line {
          &::before {
            left: -1.25rem;
          }
          .img {
            display: none;
          }
        }
      }
    }

    .description {
      width: 100%;
      height: 44%;
      margin: 0;

      .section {
        padding: 0;
        text-align: center;

        &:nth-child(n + 2) {
          padding: 0.5rem 0;
        }

        &:nth-child(3) {
          .wrapper {
            width: 40%;
            &:last-child {
              width: 50%;
            }
          }
        }
      }

      .detail {
        display: none;
      }

      .button {
        left: 1%;
        bottom: 0.25rem;
        width: 98%;
      }
    }
  }
}
</style>
