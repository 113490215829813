<template>
  <div
    :class="[
      $style.feedback,
      {
        [$style.active]: activeFeedback
          ? feedback.id === activeFeedback.id
          : false
      }
    ]"
    @click="setFeedback"
  >
    <div
      :class="[
        $style.image,
        {
          [$style.active]: activeFeedback
            ? feedback.id === activeFeedback.id
            : false
        }
      ]"
    >
      <img
        :class="[
          $style.img,
          {
            [$style.active]: activeFeedback
              ? feedback.id === activeFeedback.id
              : false
          }
        ]"
        :src="require(`@/assets/images/feedbacks/${feedback.image}.jpg`)"
      />
    </div>
    <div :class="$style.description">
      <p :class="$style.name">{{ feedback.name }}</p>
      <p :class="$style.location">{{ feedback.location }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feedback: {
      type: Object,
      default: () => {}
    },
    activeFeedback: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setFeedback() {
      this.$emit('changeFeedback', this.feedback)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/fonts/mixins.scss';

.feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -0.5rem;
    left: 0.5rem;
    content: '';
    width: 96%;
    height: 1px;
    background: $line;
    opacity: 0;
  }

  &::after {
    position: absolute;
    top: 15rem;
    left: 0.5rem;
    content: '';
    width: 96%;
    height: 1px;
    background: $line;
    opacity: 0;
  }

  &.active {
    &::after,
    &::before {
      opacity: 0.3;
    }
  }

  &:hover {
    .image {
      .img {
        filter: opacity(100%);
      }
    }
  }

  .image {
    width: 78%;
    height: 60%;

    &::before {
      position: absolute;
      top: -1.5rem;
      left: 1.5rem;
      content: '';
      height: 100%;
      width: 1px;
      background: $line;
      opacity: 0;
    }

    &::after {
      position: absolute;
      top: -1.5rem;
      right: 1.4rem;
      content: '';
      height: 100%;
      width: 1px;
      background: $line;
      opacity: 0;
    }

    &.active {
      &::after,
      &::before {
        opacity: 0.3;
      }
    }

    .img {
      width: 100%;
      height: 100%;
      filter: opacity(70%);
      object-fit: cover;
      pointer-events: none;
      transition: all 0.2s ease-in-out;

      &.active {
        filter: opacity(100%);
      }
    }
  }

  .description {
    text-align: center;
    margin: 2.35rem 0 0;

    .name {
      @include name();
    }
    .location {
      @include link();
    }
  }
}

@include for-mobile {
  .feedback {
    &::before {
      top: -0.75rem;
      left: auto;
      width: 110%;
    }

    &::after {
      left: auto;
      width: 110%;
    }

    .image {
      width: 92%;

      &::before {
        left: 0;
        height: 72%;
      }

      &::after {
        right: 0;
        height: 72%;
      }
    }

    .description {
      margin: 2rem 0 0;
    }
  }
}
</style>
