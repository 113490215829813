<template>
  <footer :class="$style.footer">
    <div :class="$style.top">
      <div :class="$style.wrapper">
        <div :class="$style.flex">
          <img
            :class="$style.logo"
            src="@/assets/icons/your-footer.svg"
            alt=""
          />
          <div :class="$style.wrap">
            <nav :class="$style.nav">
              <p :class="$style.link" v-scroll-to="'#advantages'">О нас</p>
              <p :class="$style.link" v-scroll-to="'#inStock'">
                Двери в наличии
              </p>
              <p :class="$style.link" v-scroll-to="'#underOrder'">
                Калькулятор дверей
              </p>
            </nav>
            <nav :class="$style.nav">
              <p :class="$style.link" v-scroll-to="'#production'">
                Производство
              </p>
              <p :class="$style.link" v-scroll-to="'#ourWorks'">Наши работы</p>
              <p :class="$style.link" v-scroll-to="'#contacts'">Контакты</p>
            </nav>
          </div>
          <div :class="$style.icons">
            <span
              :class="$style.icon"
              @click="goToSocial('https://www.instagram.com/doorsyours/')"
            >
              <img src="@/assets/icons/instagram.svg" />
            </span>
            <span
              :class="$style.icon"
              @click="
                goToSocial(
                  'https://www.youtube.com/channel/UCYfAvXPytnqo9cPjlqBz6YA'
                )
              "
            >
              <img src="@/assets/icons/youtube.svg" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.mobile">
      <span
        :class="$style.icon"
        @click="goToSocial('https://www.instagram.com/doorsyours/')"
      >
        <img src="@/assets/icons/instagram.svg" />
      </span>
      <span
        :class="$style.icon"
        @click="
          goToSocial('https://www.youtube.com/channel/UCYfAvXPytnqo9cPjlqBz6YA')
        "
      >
        <img src="@/assets/icons/youtube.svg" />
      </span>
    </div>
    <div :class="$style.bottom">
      <div :class="$style.wrapper">
        <p :class="$style.text">
          ©2021 «YourDoors» - производство входных и межкомнатных дверей
        </p>
        <div :class="$style.icons">
          <img src="@/assets/icons/pay1.svg" />
          <img src="@/assets/icons/pay2.svg" />
          <img src="@/assets/icons/pay3.svg" />
          <img src="@/assets/icons/pay4.svg" />
          <img src="@/assets/icons/pay5.svg" />
          <img src="@/assets/icons/pay6.svg" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    goToSocial(link) {
      location.href = link
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -6.25rem 0 1px rgba(52, 52, 52, 0.3),
    0 -10.5rem 0 1.875rem rgba(52, 52, 52, 0.1);

  .wrapper {
    max-width: 89.375rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .top {
    width: 100%;
    height: 10.625rem;
    opacity: 0.9;
    background: $footer;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .logo {
        width: 16%;
      }

      .wrap {
        display: flex;
        justify-content: space-between;
        width: 40%;
      }

      .nav {
        display: flex;
        flex-direction: column;
        width: 36%;
        padding: 0;

        .link {
          width: 100%;
          padding: 0.5rem 0;
          border-bottom: 1px solid $white;
          @include link();
          color: $white;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:last-child {
            border: none;
          }

          &:hover {
            color: $brown;
            padding: 0.5rem 0.25rem;
          }
        }
      }

      .icons {
        display: flex;
        justify-content: space-between;
        width: 7%;
        margin: 1rem 0 0;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem;
          background: $brown;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            img {
              filter: invert(25%);
              transition: all 0.2s ease-in-out;
            }
          }

          &:nth-child(2) {
            background: $ligth-green;
          }

          &:last-child {
            padding: 0.2rem;
          }
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  .bottom {
    width: 100%;
    height: 3.75rem;
    background: $footer;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .text {
      @include smallText();
      color: $footer-gray;
      letter-spacing: 0.15px;
    }

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 17.2%;
    }
  }
}

@include for-tablet {
  .footer {
    .top {
      .wrapper {
        .flex {
          .logo {
            width: 30%;
          }

          .wrap {
            display: none;
          }

          .icons {
            justify-content: flex-end;
            width: 13%;

            .icon {
              margin: 0 0 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      .wrapper {
        .text {
          width: 70%;
        }

        .icons {
          width: 30%;
        }
      }
    }
  }
}

@include for-mobile {
  .footer {
    .top {
      .wrapper {
        .flex {
          flex-direction: column;
          justify-content: center;

          .logo {
            width: 65%;
          }

          .icons {
            display: none;
          }
        }
      }
    }

    .mobile {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0.5rem 0;
      opacity: 0.97;
      background: $footer;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        background: $brown;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          img {
            filter: invert(25%);
            transition: all 0.2s ease-in-out;
          }
        }

        &:nth-child(2) {
          margin: 0 0 0 1rem;
          background: $ligth-green;
        }

        &:last-child {
          padding: 0.2rem;
        }
      }
    }

    .bottom {
      height: auto;
      padding: 1rem;

      .wrapper {
        flex-direction: column;

        .text {
          width: 100%;
          margin: 0 0 1rem 0;
          text-align: center;
        }

        .icons {
          width: 80%;
        }
      }
    }
  }
}
</style>
