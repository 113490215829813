<template>
  <h1 :class="[$style.title, $style[color], $style[position], $style[weight]]">
    <slot />
  </h1>
</template>

<script>
export default {
  props: {
    color: String,
    position: String,
    weight: String
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts/mixins.scss';

.title {
  @include title();
  letter-spacing: 0.5px;

  &.green {
    color: $dark-green;
  }

  &.white {
    color: $white;
  }

  &.gray {
    color: $dark-gray;
  }

  &.center {
    text-align: center;
  }

  &.bold {
    text-shadow: #707070 1px 0;
  }

  &.bold-white {
    text-shadow: #ffffff 1px 0;
  }
}
</style>
