export const feedbacks = [
  {
    id: 1,
    name: 'Письменная Наталья',
    image: 'feedback1',
    location: 'Коренёвка',
    feedback: `
    Очень довольна компанией YourDoors. Мне помогли выбрать дверь,
    замерщик приехал в точно назначенное время без опозданий.
    Замеры были правильные, поэтому с установкой никаких проблем не возникло.
    Доставили дверь абсолютно бесплатно.
    `
  },
  {
    id: 2,
    name: 'Можейко Павел',
    image: 'feedback2',
    location: 'Прибор',
    feedback: `
    Заказывали входную дверь в компании YourDoors и остались очень довольны!
    При первом обращении менеджер проконсультировал по все заданным вопросам,
    замерщик приехал на объект в указанный срок. С установкой ребята справились
    на ура, быстро и аккуратно. Видно, что ребята очень ответственно и профессионально
    относятся к своей работе. Все обязательства выполнены в срок. Порадовала бесплатная доставка.
    `
  },
  {
    id: 3,
    name: 'Сергей Кривда',
    image: 'feedback3',
    location: 'Зелёная поляна',
    feedback: `
    Долго искали компанию, которая смогла бы выполнить наш нестандартный заказ по дверям.
    Обратились в YourDoors и остались довольны. Менеджер понял нас с полуслова, двери изготовили в 
    срок по выполненным их мастером замерам. Монтаж очень качественный. Доставили дверь бесплатно.
    `
  },
  {
    id: 4,
    name: 'Максим Ковалев',
    image: 'feedback5',
    location: 'Гомель',
    feedback: `
    Хочу выразить свою благодарность специалистам компании YourDoors.
    Они не только терпеливо отвечали на все мои вопросы, но и быстро все сделали.
    Изготовили двери именно такие, как я хотел. Доставили бесплатно.
    Установили быстро и качественно, придраться не к чему.
    `
  }
]
