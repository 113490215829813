<template>
  <section :class="$style.ourWorks">
    <Title :class="$style.title" position="center" id="ourWorks" weight="bold">
      НАШИ РАБОТЫ
    </Title>
    <div v-swiper:mySwiper="swiperOption">
      <Button :class="$style.left" type="secondary" @click="showPrev">
        <img src="@/assets/icons/arrowWhite.svg" />
      </Button>
      <Button :class="$style.right" type="secondary" @click="showNext">
        <img :class="$style.icon" src="@/assets/icons/arrowWhite.svg" />
      </Button>
      <div class="swiper-wrapper" @click="onClick">
        <div class="swiper-slide" v-for="door in ourWorksData" :key="door.id">
          <Slider :door="door" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <Gallery />
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Slider from '@/components/common/OurWorksSlider'
import Button from '@/components/basic/Button'
import Gallery from '@/components/common/Gallery'

import { mapGetters, mapMutations } from 'vuex'

import { ourWorks } from '@/application/ourWorks'
import { directive } from 'vue-awesome-swiper'

import SwiperCore, { Pagination, Navigation } from 'swiper/core'

SwiperCore.use([Pagination, Navigation])

export default {
  components: {
    Title,
    Slider,
    Button,
    Gallery
  },

  directives: {
    swiper: directive
  },

  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: false
        },
        navigation: true,
        slidesPerView: 2,
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        speed: 700,
        allowTouchMove: false,
        breakpoints: {
          1500: {
            spaceBetween: 180
          },
          1280: {
            spaceBetween: 120
          },
          320: {
            spaceBetween: 20
          }
        },
        on: {
          loopFix: function() {
            this.loopDestroy()
            this.loopCreate()
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters(['allFeedbacks', 'location']),

    ourWorksData() {
      return ourWorks
    },

    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },

  watch: {
    location() {
      this.mySwiper.loopDestroy()
      this.mySwiper.update()
      this.mySwiper.loopCreate()
      this.mySwiper.slideTo(2, 1000, false)
    }
  },

  methods: {
    ...mapMutations(['setImages', 'setActiveFeedback', 'changeStatusGallery']),

    showNext() {
      this.mySwiper.slideNext()
    },

    showPrev() {
      this.mySwiper.slidePrev()
    },

    onClick(e) {
      const image = e.target.closest('.OurWorksSlider_image_1k6Ek')
      const slide = e.target.closest('.swiper-slide')
      const button = e.target.closest('button')

      if (image) {
        this.setImages(this.ourWorksData[slide.dataset.swiperSlideIndex].images)
        this.changeStatusGallery(true)
        document.body.style.overflow = 'hidden'
      }

      if (button) {
        const feedbackId = this.ourWorksData[slide.dataset.swiperSlideIndex]
          .feedbackId
        this.goToFeedback(feedbackId)
        this.$scrollTo('#feedbacks')
      }
    },

    goToFeedback(id) {
      const activeFeedback = this.allFeedbacks.find(
        feedback => feedback.id === id
      )
      this.setActiveFeedback(activeFeedback)
    }
  },

  mounted() {
    this.mySwiper.slideTo(2, 1000, false)
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.swiper-container {
  width: 100%;
  height: 38rem;
  margin: 4rem 0;

  .swiper-slide {
    height: 33.15rem;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    pointer-events: none;

    .OurWorksSlider_ourWorksSlider_2s4_c {
      .OurWorksSlider_image_1k6Ek {
        transform: scale(0.85);

        .OurWorksSlider_img_1wAPf {
          filter: opacity(70%) sepia(70%);
        }
      }

      .OurWorksSlider_description_8l8Vx {
        transform: scale(0.85);
        opacity: 0;
      }
    }
  }

  .swiper-slide-prev {
    .OurWorksSlider_ourWorksSlider_2s4_c {
      flex-direction: row-reverse;
    }
  }

  .swiper-slide-active {
    .OurWorksSlider_ourWorksSlider_2s4_c {
      .OurWorksSlider_image_1k6Ek {
        .OurWorksSlider_fullscreen_303pu {
          display: flex;
        }
      }

      .OurWorksSlider_description_8l8Vx {
        opacity: 1;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;

    .swiper-pagination-bullet {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 0 8px;
      border-radius: 2.5px;
      background: #000;
      opacity: 0.2;
    }

    .swiper-pagination-bullet-active {
      width: 1.938rem;
      border-radius: 2.5px;
      background: $dark-green;
      opacity: 1;
    }
  }
}

@include for-tablet {
  .swiper-container {
    height: 67.5rem;
    margin: 3rem 0 0;

    .swiper-slide-prev {
      .OurWorksSlider_ourWorksSlider_2s4_c {
        flex-direction: column;
      }
    }

    .swiper-slide-active {
      .OurWorksSlider_ourWorksSlider_2s4_c {
        .OurWorksSlider_description_8l8Vx {
          width: 145%;
          height: 90%;
        }
      }
    }

    .swiper-pagination {
      bottom: 1rem;
    }
  }
}

@include for-mobile {
  .swiper-container {
    height: 50rem;
    margin: 2rem 0 0;

    .swiper-slide {
      height: 33rem;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      .OurWorksSlider_ourWorksSlider_2s4_c {
        .OurWorksSlider_description_8l8Vx {
          width: 116%;
          height: 86%;
          top: 17rem;
        }
      }
    }

    .swiper-slide-prev {
      .OurWorksSlider_ourWorksSlider_2s4_c {
        flex-direction: column;
      }
    }

    .swiper-slide-active {
      .OurWorksSlider_ourWorksSlider_2s4_c {
        .OurWorksSlider_description_8l8Vx {
          width: 194%;
          height: 86%;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;
    }
  }
}
</style>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.ourWorks {
  position: relative;
  bottom: 11.25rem;
  width: 100%;
  padding: 12rem 0 1.5rem;
  background: $bg-gray;

  .title {
    padding: 5rem 0 0;
  }

  .left {
    position: absolute;
    top: 40%;
    left: 13.25%;
    z-index: 10;
  }

  .right {
    position: absolute;
    top: 40%;
    right: 13.25%;
    z-index: 10;
  }

  .icon {
    transform: rotate(180deg);
  }
}

@include for-tablet {
  .ourWorks {
    bottom: 13.25rem;

    .title {
      padding: 4.5rem 0 0;
    }

    .left {
      top: 21.5%;
      left: 3%;
    }

    .right {
      top: 21.5%;
      right: 3%;
    }
  }
}

@include for-mobile {
  .ourWorks {
    .left {
      top: 15.5%;
      left: 5%;
    }

    .right {
      top: 15.5%;
      right: 5%;
    }
  }
}
</style>
