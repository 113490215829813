export const ourWorks = [
  {
    id: 1,
    model: 'YD2',
    image: 'YD2-1',
    images: [
      { title: '', url: require('@/assets/images/door/YD2-1.jpg') },
      { title: '', url: require('@/assets/images/door/YD2-2.jpg') }
    ],
    description: 'Входная деревянная дверь выполненная из массива дуба',
    time: '14 дней',
    material: 'Дуб',
    location: 'Прибор, Гомельский район',
    size: '970*2170',
    price: 4600,
    feedbackId: 2,
    detailed:
      'Изготовление дверей на заказ из массива дерева. Широкий выбор расцветок. Индивидуальные и типовые проекты по разумным ценам. Выезд мастера для точных замеров. Изготовление любой сложности. Цена рассчитывается индивидуально, в зависимости от материала, дизайна и размеров изделия.'
  },
  {
    id: 2,
    model: 'YD1',
    image: 'YD1-1',
    images: [
      {
        title: '',
        url: require('@/assets/images/door/YD1-1.jpg')
      },
      {
        title: '',
        url: require('@/assets/images/door/YD1-2.jpg')
      },
      {
        title: '',
        url: require('@/assets/images/door/YD1-4.jpg')
      },
      {
        title: '',
        url: require('@/assets/images/door/YD1-3.jpg')
      }
    ],
    description: 'Входная деревянная дверь выполненная из массива дуба',
    time: '14 дней',
    material: 'Дуб',
    location: 'Зелёная поляна, Гомельский район',
    size: '970*2170',
    price: 3875,
    feedbackId: 3,
    detailed:
      'Входные двери из массива изготавливаются по индивидуальным проектам, а значит будут соответствовать всем вашим требованиям по размеру, форме и дизайну. Вы можете найти понравившуюся модель входной двери из дуба, либо выбрать авторский дизайн-проект. Итоговая стоимость зависит от сложности изготовления, используемых материалов и габаритов конструкции.'
  },
  {
    id: 3,
    model: 'YDPersonal-1',
    image: 'korenevka-1',
    images: [
      { title: '', url: require('@/assets/images/door/korenevka-1.jpg') }
    ],
    description: 'Входная деревянная дверь выполненная из массива дуба',
    time: '14 дней',
    material: 'Дуб',
    location: 'Коренёвка, Гомельский район',
    size: '1600*2420',
    price: 4500,
    feedbackId: 1,
    detailed:
      'Входные двери из массива изготавливаются по индивидуальным проектам, а значит будут соответствовать всем вашим требованиям по размеру, форме и дизайну. Вы можете найти понравившуюся модель входной двери из дуба, либо выбрать авторский дизайн-проект. Итоговая стоимость зависит от сложности изготовления, используемых материалов и габаритов конструкции.'
  },
  {
    id: 4,
    model: 'YDPersonal-2',
    image: 'markoni-1',
    images: [
      { title: '', url: require('@/assets/images/door/markoni-1.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-2.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-3.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-4.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-5.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-6.jpg') },
      { title: '', url: require('@/assets/images/door/markoni-7.jpg') }
    ],
    description: 'Входная деревянная дверь выполненная из массива дуба',
    time: '14 дней',
    material: 'Дуб',
    location: 'Гомель',
    size: null,
    price: null,
    feedbackId: 4,
    detailed:
      'Эксклюзивные входные двери из массива по индивидуальным проектам. Изготавливаем в собственном цеху под строгим контролем качества. Полное соответствие готовой модели вашим размерам и пожеланиям. Есть готовые входные двери из дуба и других пород. Цена рассчитывается индивидуально.'
  },
  {
    id: 5,
    model: 'YDPersonal-3',
    image: 'minsk-1',
    images: [
      { title: '', url: require('@/assets/images/door/minsk-1.jpg') },
      { title: '', url: require('@/assets/images/door/minsk-2.jpg') },
      { title: '', url: require('@/assets/images/door/minsk-3.jpg') }
    ],
    description: 'Входная деревянная дверь выполненная из массива дуба',
    time: '21 дней',
    material: 'Дуб',
    location: 'Минск',
    size: '1500*2800',
    price: null,
    feedbackId: null,
    detailed:
      'Классический стиль двери в нестандартных размерах проёма. Открывание двери - штульп ( две створки) с верхним добором. Входные двери из массива изготавливаются по индивидуальным проектам, а значит будут соответствовать всем вашим требованиям по размеру, форме и дизайну. Вы можете найти понравившуюся модель входной двери из дуба, либо выбрать авторский дизайн-проект. Итоговая стоимость зависит от сложности изготовления, используемых материалов и габаритов конструкции.'
  }
]
