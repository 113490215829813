<template>
  <section :class="$style.map">
    <div :class="$style.point" id="contacts"></div>
    <Ymap />
    <div :class="[$style.address, { [$style.address_disabled]: !show }]">
      <div
        :class="[$style.drop, { [$style.drop_plus]: !show }]"
        @click="show = !show"
      ></div>
      <div :class="$style.wrapper" v-if="show">
        <div :class="$style.lines"></div>
        <div :class="$style.section">
          <h5 :class="$style.title">Производство:</h5>
          <div :class="$style.text">
            <img :class="$style.icon" src="@/assets/icons/phone-number.svg" />
            <span :class="$style.phone">
              <a href="tel:+375296993535">+375 29 699 35 35</a>
              <span :class="$style.delimiter">/</span>
              <a href="tel:+375296569238">+375 29 656 92 38</a>
            </span>
          </div>
          <p :class="$style.text">
            <img :class="$style.icon" src="@/assets/icons/address.svg" />
            247511, Гомельская обл., д. Борщевка 10
          </p>
        </div>
        <div :class="$style.section">
          <h5 :class="$style.title">Отдел продаж:</h5>
          <div :class="$style.text">
            <img :class="$style.icon" src="@/assets/icons/phone-number.svg" />
            <span :class="$style.phone">
              <a href="tel:+375296993535">+375 29 699 35 35</a>
              <span :class="$style.delimiter">/</span>
              <a href="tel:+375296569238">+375 29 656 92 38</a>
            </span>
          </div>
          <p :class="$style.text">
            <img :class="$style.icon" src="@/assets/icons/address.svg" />
            246000, г. Гомель 50 лет БССР д.7 оф.6
          </p>
          <p :class="[$style.text, $style.email]">
            <img :class="$style.icon" src="@/assets/icons/mail.svg" />
            <a href="mailto:doorsyour@gmail.com">
              e-mail to: doorsyour@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Ymap from '@/components/common/Ymap'
export default {
  components: {
    Ymap
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.map {
  position: relative;
  width: 100%;
  height: 47.375rem;

  .point {
    position: absolute;
    left: auto;
    right: auto;
    top: -25rem;
  }

  .address {
    position: absolute;
    left: 13.3%;
    top: 14.5%;
    z-index: 3000;
    width: 35.35rem;
    height: 45%;
    padding: 2rem 3rem 2rem 4.5rem;
    background: $line;
    transition: all 0.3s ease-in-out;

    &_disabled {
      width: 2.25rem !important;
      height: 2.25rem !important;
      padding: 0 !important;
      background: transparent;
    }

    .drop {
      display: none;
    }

    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        position: absolute;
        top: -2.7rem;
        right: -5.2rem;
        width: 39.5rem;
        height: 1px;
        opacity: 0.3;
        background: $line;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -2.7rem;
        right: -5.2rem;
        width: 39.5rem;
        height: 1px;
        opacity: 0.3;
        background: $line;
      }

      .lines {
        &::before {
          content: '';
          position: absolute;
          right: -3.6rem;
          top: -4.25rem;
          height: 26rem;
          width: 1px;
          opacity: 0.3;
          background: $line;
        }

        &::after {
          content: '';
          position: absolute;
          left: -5.15rem;
          top: -4.25rem;
          height: 26rem;
          width: 1px;
          opacity: 0.3;
          background: $line;
        }
      }

      .section {
        padding: 1.25rem 0 1rem;
        color: $white;

        &:first-child {
          border-bottom: 1px solid $white;

          .text {
            &:nth-child(2) {
              margin: 0 0 0.3rem 0;
            }
          }
        }

        .title {
          margin: 0 0 0.5rem 0;
          @include subtitleText();
        }

        .text {
          margin: 0 0 0.5rem 0;
          @include subtext();
          letter-spacing: 0.5px;

          &:nth-child(n + 3) {
            letter-spacing: 0.1px;
          }

          .phone {
            .delimiter {
              margin: 0 0.5rem;
            }
          }

          a {
            text-decoration: none;
            color: $white;
            transition: all .3s ease;

            &:hover {
              color: $light-brown;
            }
          }
        }

        .icon {
          margin: 0 0.35rem 0 0;
        }
      }
    }
  }
}

@include for-tablet {
  .map {
    .address {
      left: 12.3%;
      top: 14%;
    }
  }
}

@include for-mobile {
  .map {
    bottom: 8rem;
    height: 41.375rem;

    .address {
      left: auto;
      right: 0;
      top: 9%;
      width: 85%;
      height: 57%;
      margin: 0 1.5rem;
      padding: 1.25rem 1.5rem 1.25rem 2rem;
      overflow: hidden;

      .drop {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3005;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.25rem;
        height: 2.25rem;
        background: $transparent-gray;

        &::before {
          content: '';
          position: absolute;
          right: 0.5rem;
          top: 1.13rem;
          width: 1.3rem;
          height: 1px;
          background: $white;
        }

        &_plus {
          background: $line;
          &::after {
            content: '';
            position: absolute;
            right: 1.13rem;
            top: 0.5rem;
            height: 1.3rem;
            width: 1px;
            background: $white;
          }
        }
      }

      .wrapper {
        &::before,
        &::after,
        .lines {
          display: none;
        }

        .section {
          .title {
            margin: 0 0 0.5rem 0;
            letter-spacing: 1px;
          }

          .text {
            display: flex;
            align-items: flex-start;
            margin: 0;

            &:nth-child(2) {
              margin: 0 0 0.5rem 0;
            }

            .icon {
              width: 1rem;
            }

            .phone {
              display: flex;
              flex-direction: column;

              .delimiter {
                display: none;
              }
            }
          }

          .email {
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
