export const advantages = [
  {
    id: 1,
    slide: 'slide-1',
    items: [
      {
        title: 'Качественные материалы',
        text:
          'Используем древесину, соответствующую высоким стандартам качества. Тщательно проверяем, чтобы она соответствовала по всем параметрам.',
        image: 'advantage1'
      },
      {
        title: 'Современное оборудование и технологии',
        text:
          'Используем лучшие средства и способы для изготовления дверей на заказ. Регулярно обновляем оборудование для повышения качества работы.',
        image: 'advantage2'
      },
      {
        title: 'Контроль качества',
        text:
          'Каждый этап строго контролируется, не допуская ошибок при производстве. Тщательно следим, чтобы дверь полностью соответствовала вашим ожиданиям.',
        image: 'advantage3'
      }
    ]
  },
  {
    id: 2,
    slide: 'slide-2',
    items: [
      {
        title: 'Индивидуальный подход',
        text:
          'Работаем с нестандартными решениями: размеры, конфигурация, дизайн. Воплощаем самые сложные дизайнерские решения в лучшем виде.',
        image: 'advantage4'
      },
      {
        title: 'Монтаж от профессионалов',
        text:
          'Профессионалы выполнят работу быстро и качественно с гарантией. Соблюдаем все технологические процессы, строго следуя их регламенту.',
        image: 'advantage5'
      },
      {
        title: 'Проект под ключ',
        text:
          'Входные деревянные двери под заказ делаются с полным циклом работ. От консультации до монтажа и предоставления дополнительных услуг.',
        image: 'advantage6'
      }
    ]
  }
]
