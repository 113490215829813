<template>
  <div :class="$style.container" id="app">
    <Header />
    <main>
      <Advantages />
      <Discount />
      <InStock />
      <UnderOrder />
      <Production />
      <OurWorks />
      <MeasurerCall />
      <Feedbacks />
      <Map />
      <transition name="fade">
        <PopUp
          v-if="popup.form || popup.gratitude || popup.loading"
          :popup="popup"
        />
      </transition>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import Advantages from '@/components/Advantages'
import Discount from '@/components/Discount'
import InStock from '@/components/InStock'
import UnderOrder from '@/components/UnderOrder'
import Production from '@/components/Production'
import OurWorks from '@/components/OurWorks'
import MeasurerCall from '@/components/MeasurerCall'
import Feedbacks from '@/components/Feedbacks'
import Map from '@/components/Map'
import Footer from '@/components/common/Footer'
import PopUp from '@/components/common/PopUp'

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Advantages,
    Discount,
    InStock,
    UnderOrder,
    Production,
    OurWorks,
    MeasurerCall,
    Feedbacks,
    Map,
    Footer,
    PopUp
  },
  computed: {
    ...mapGetters({
      popup: 'popup'
    })
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  overflow: hidden;
}
</style>
