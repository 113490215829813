import axios from 'axios'

export default {
  state: {
    name: '',
    phone: ''
  },

  mutations: {
    setName(state, name) {
      state.name = name
    },

    setPhone(state, phone) {
      state.phone = phone
    }
  },

  actions: {
    postContactForm({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://api.ydoors.by', {
            name: state.name,
            message: state.phone
          })
          .then(response => {
            console.log(response)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject()
          })
      })
    }
  }
}
