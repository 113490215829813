export const inStock = [
  {
    id: 1,
    model: 'YD1',
    description:
      'Входная деревянная дверь, выполненная из массива дуба, с заполнением из стеклопакетов',
    material: 'Массив дуба',
    size: '970*2170 мм',
    thickness: '95 мм',
    possibilities:
      'Любые размеры, цвета, дизайн, отделка. Все изделия изготавливаются на заказ.',
    price: 3850,
    image: 'YD1-1',
    images: [{ path: 'YD1-1' }, { path: 'YD1-2' }, { path: 'YD1-3' }],
    detailed: `
    Входные двери из массива изготавливаются по индивидуальным проектам,
    а значит будут соответствовать всем вашим требованиям по размеру,
    форме и дизайну. Вы можете найти понравившуюся модель входной двери из дуба,
    либо выбрать авторский дизайн-проект. Итоговая стоимость зависит от сложности
    изготовления, используемых материалов и габаритов конструкции.`
  },
  {
    id: 2,
    model: 'YD2',
    description:
      'Входная деревянная дверь, выполненная из массива дуба, с заполнением из стеклопакетов',
    material: 'Массив дуба',
    size: '970*2170 мм',
    thickness: '95 мм',
    possibilities:
      'Любые размеры, цвета, дизайн, отделка. Все изделия изготавливаются на заказ.',
    price: 4300,
    image: 'YD2-1',
    images: [{ path: 'YD2-1' }, { path: 'YD2-2' }],
    detailed: `
    Входные двери из массива изготавливаются по индивидуальным проектам,
    а значит будут соответствовать всем вашим требованиям по размеру, форме и дизайну.
    Вы можете найти понравившуюся модель входной двери из дуба,
    либо выбрать авторский дизайн-проект. Итоговая стоимость зависит
    от сложности изготовления, используемых материалов и габаритов конструкции.`
  },
  {
    id: 3,
    model: 'YD3',
    description:
      'Входная деревянная дверь, выполненная из массива дуба, с заполнением из стеклопакетов, с дополнительным боковым добором',
    material: 'Массив дуба',
    size: '1300*2170 мм',
    thickness: '95 мм',
    possibilities:
      'Любые размеры, цвета, дизайн, отделка, возможны различные конструктивные изменения боковых и верхних доборов. Все изделия изготавливаются на заказ.',
    price: 4700,
    image: 'YD3-1',
    images: [{ path: 'YD3-1' }],
    detailed: `
    Входные двери из массива изготавливаются по индивидуальным проектам,
    а значит будут соответствовать всем вашим требованиям по размеру,
    форме и дизайну. Вы можете найти понравившуюся модель входной двери из дуба,
    либо выбрать авторский дизайн-проект. Итоговая стоимость зависит от
    сложности изготовления, используемых материалов и габаритов конструкции.`
  },
  {
    id: 4,
    model: 'YD4',
    description:
      'Входная деревянная дверь, выполненная из массива дуба, с заполнением',
    material: 'Массив дуба',
    size: '917*2170 мм',
    thickness: '95 мм',
    possibilities:
      'Любые размеры, цвета, дизайн, отделка. Все изделия изготавливаются на заказ.',
    price: 5200,
    image: 'YD4-1',
    images: [{ path: 'YD4-1' }],
    detailed: `
    Входные двери из массива изготавливаются по индивидуальным проектам, а значит будут
    соответствовать всем вашим требованиям по размеру, форме и дизайну. Вы можете найти
    понравившуюся модель входной двери из дуба, либо выбрать авторский дизайн-проект.
    Итоговая стоимость зависит от сложности изготовления, используемых материалов и
    габаритов конструкции.`
  }
]
