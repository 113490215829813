var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.underOrderSlider},[_c('div',{class:[
      _vm.$style.image,
      ( _obj = {}, _obj[_vm.$style.active] = _vm.selectedDoor ? _vm.selectedDoor.id === _vm.door.id : false, _obj )
    ]},[_c('img',{class:_vm.$style.img,attrs:{"src":require(("@/assets/images/door/" + (_vm.door.image) + ".jpg")),"alt":"door"}}),_c('div',{class:[
        _vm.$style.select,
        ( _obj$1 = {}, _obj$1[_vm.$style.active] = _vm.selectedDoor ? _vm.selectedDoor.id === _vm.door.id : false, _obj$1 )
      ]},[(_vm.selectedDoor ? _vm.selectedDoor.id === _vm.door.id : false)?_c('img',{attrs:{"src":require("@/assets/icons/select.svg")}}):_vm._e()])]),_c('h4',{class:_vm.$style.doorName},[_vm._v(_vm._s(_vm.door.name))])])}
var staticRenderFns = []

export { render, staticRenderFns }