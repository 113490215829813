<template>
  <div
    :class="[{ [$style.inputWrapper]: plus }, { [$style.plusGray]: plusGray }]"
  >
    <input
      :class="[$style.input, $style[type], { [$style.disabled]: disabled }]"
      type="text"
      autocomplete="off"
      :value="value"
      v-mask="phoneMask || nameMask || nomask"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  directives: { mask },
  props: {
    type: String,
    value: String,
    phoneMask: String,
    nameMask: Object,
    placeholder: String,
    plus: Boolean,
    plusGray: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      nomask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ }
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/fonts/mixins.scss';
@import '@/assets/styles/colors.scss';

.inputWrapper {
  position: relative;

  &::before {
    content: '+';
    position: absolute;
    top: 0.65rem;
    left: 0.85rem;
    color: $white;
  }

  .input {
    padding: 0.7rem 1.5rem;
  }
}

.plusGray {
  &::before {
    color: $dark-gray;
  }
}

.input {
  width: 100%;
  padding: 0.7rem 1rem;
  outline: none;
  background: transparent;
  @include smallText();
  font-size: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;

  &.primary {
    color: $light-gray;
    border: 1px solid $transparent-gray-medium;

    &::placeholder {
      color: $light-gray;
    }
  }

  &.secondary {
    color: $dark-gray;
    border: 1px solid $dark-gray;

    &::placeholder {
      color: $dark-gray;
    }
  }
}

.disabled {
  border-color: $error !important;
}
</style>
