export default {
  DOORS: [
    {
      id: 1,
      name: 'YD1',
      image: 'YD1-1',
      price: 3850
    },
    {
      id: 2,
      name: 'YD2',
      image: 'YD2-1',
      price: 4300
    },
    {
      id: 3,
      name: 'YD3',
      image: 'YD3-1',
      price: 4700
    },
    {
      id: 4,
      name: 'YD4',
      image: 'YD4-1',
      price: 5200
    },
    {
      id: 1,
      name: 'YD1',
      image: 'YD1-1',
      price: 3850
    },
    {
      id: 2,
      name: 'YD2',
      image: 'YD2-1',
      price: 4300
    },
    {
      id: 3,
      name: 'YD3',
      image: 'YD3-1',
      price: 4700
    },
    {
      id: 4,
      name: 'YD4',
      image: 'YD4-1',
      price: 5200
    }
  ],
  DOOR_WIDTH: [
    {
      id: 1,
      name: 'width',
      value: '870, мм (ширина)',
      price: 0
    },
    {
      id: 2,
      name: 'width',
      value: '920, мм (ширина)',
      price: 0
    },
    {
      id: 3,
      name: 'width',
      value: '970, мм (ширина)',
      price: 0
    },
    {
      id: 4,
      name: 'width',
      value: '1020, мм (ширина)',
      price: 120
    },
    {
      id: 5,
      name: 'width',
      value: '1070, мм (ширина)',
      price: 120
    },
    {
      id: 6,
      name: 'width',
      value: '1120, мм (ширина)',
      price: 120
    },
    {
      id: 7,
      name: 'width',
      value: '1170, мм (ширина)',
      price: 120
    },
    {
      id: 8,
      name: 'width',
      value: '1220, мм (ширина)',
      price: 120
    }
  ],
  DOOR_HEIGHT: [
    {
      id: 1,
      name: 'height',
      value: '1870, мм (высота)',
      price: 0
    },
    {
      id: 2,
      name: 'height',
      value: '1970, мм (высота)',
      price: 0
    },
    {
      id: 3,
      name: 'height',
      value: '2070, мм (высота)',
      price: 0
    },
    {
      id: 4,
      name: 'height',
      value: '2170, мм (высота)',
      price: 0
    },
    {
      id: 5,
      name: 'height',
      value: '2270, мм (высота)',
      price: 65
    },
    {
      id: 6,
      name: 'height',
      value: '2370, мм (высота)',
      price: 65
    },
    {
      id: 7,
      name: 'height',
      value: '2470, мм (высота)',
      price: 65
    },
    {
      id: 8,
      name: 'height',
      value: '2570, мм (высота)',
      price: 65
    },
    {
      id: 9,
      name: 'height',
      value: '2670, мм (высота)',
      price: 65
    },
    {
      id: 10,
      name: 'height',
      value: '2870, мм (высота)',
      price: 65
    },
    {
      id: 11,
      name: 'height',
      value: '2970, мм (высота)',
      price: 65
    }
  ],
  DOORS_TYPE: [
    {
      id: 1,
      name: 'type',
      value: 'Одностворчатая',
      price: 0
    },
    {
      id: 2,
      name: 'type',
      value: 'Двустворчатая',
      price: 850
    }
  ],
  SIDE_EXTENSION: [
    {
      id: 1,
      name: 'extension',
      value: 'Без добора',
      price: 0
    },
    {
      id: 2,
      name: 'extension',
      value: 'Добор справа',
      price: 850
    },
    {
      id: 3,
      name: 'extension',
      value: 'Добор слева',
      price: 850
    },
    {
      id: 4,
      name: 'extension',
      value: 'Добор справа и слева',
      price: 1700
    }
  ],
  HARDWARE: [
    {
      id: 1,
      name: 'doorCloser',
      value: 'Скрытый доводчик двери',
      price: 950,
      active: false
    },
    {
      id: 2,
      name: 'nightCastle',
      value: 'Ночной замок',
      price: 120,
      active: false
    }
  ],
  DOOR_HINGES: [
    {
      id: 1,
      name: 'hinges',
      value: 'Усиленные петли TKZ',
      price: 0
    },
    {
      id: 2,
      name: 'hinges',
      value: 'Усиленные петли Siegenia',
      price: 0
    }
  ],
  DOOR_LOCK: [
    {
      id: 1,
      name: 'lock',
      value: 'Штыревой замок',
      price: 0
    },
    {
      id: 2,
      name: 'lock',
      value: 'Полуавтоматический замок',
      price: 120
    },
    {
      id: 3,
      name: 'lock',
      value: 'Многозапорный замок',
      price: 220
    }
  ],
  DELIVERY_PRICE: [
    {
      id: 1,
      name: 'delivery',
      value: 'Доставка до двери (в пределах города)',
      price: 0
    },
    {
      id: 2,
      name: 'delivery',
      value: 'Доставка за город (до 50 км)',
      price: 120
    },
    {
      id: 3,
      name: 'delivery',
      value: 'Доставка за город (свыше 50 км)',
      price: 'расчёт производится индивидуально'
    }
  ],
  ADDITIONAL_SERVICES: [
    {
      id: 1,
      value: 'Установка двери в готовый проем',
      price: 250,
      active: false,
      name: 'installing'
    },
    {
      id: 2,
      value: 'Подготовка (увеличение проема)',
      price: 120,
      active: false,
      name: 'preparation'
    }
    // {
    //   id: 3,
    //   value: "Заделка швов монтажной пеной",
    //   price: 50,
    //   active: false,
    //   name: "sealing",
    // },
    // {
    //   id: 4,
    //   value: "Вывоз мусора после установки",
    //   price: 50,
    //   active: false,
    //   name: "garbageRemoval",
    // },
    // {
    //   id: 5,
    //   value: "Разбор старой коробки",
    //   price: 50,
    //   active: false,
    //   name: "parsing",
    // },
  ]
  // DOOR_FRAME_OPTION: [
  //   {
  //     id: 1,
  //     name: "box",
  //     value: "Стандартная",
  //     price: 0,
  //   },
  //   {
  //     id: 2,
  //     name: "box",
  //     value: "Не стандартная",
  //     price: 300,
  //   },
  // ],
  // MATERIAL: [
  //   {
  //     id: 1,
  //     name: "material",
  //     value: "Дуб",
  //     price: 0
  //   },
  //   {
  //     id: 2,
  //     name: "material",
  //     value: "Ольха",
  //     price: 100
  //   },
  //   {
  //     id: 3,
  //     name: "material",
  //     value: "Сосна",
  //     price: 200
  //   },
  //   {
  //     id: 4,
  //     name: "material",
  //     value: "Клен",
  //     price: 300
  //   }
  // ],
}
