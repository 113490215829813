<template>
  <yandex-map :settings="settings" :coords="coords" :zoom="17" :controls="[]">
    <ymap-marker marker-id="123" :coords="coordsIcon" :icon="Icon" />
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
  components: {
    yandexMap,
    ymapMarker
  },
  computed: {
    coords() {
      return this.windowWidth > 1280 ? [52.4535, 30.993] : [52.4535, 30.9973]
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      coordsIcon: [52.4542, 30.9969],
      Icon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/icons/location.svg'),
        imageSize: [75, 75],
        imageOffset: [-20, -50],
        content: '',
        contentOffset: [25, 20],
        contentLayout:
          '<span><svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.2V23h11.4a.6.6 0 0 0 .6-.6V8.6a.6.6 0 0 0-.6-.6h-4.954l-.158 2H20v1h-3.79l-.159 2H20v1h-4.028l-.373 4.715a.303.303 0 0 1-.323.284.306.306 0 0 1-.276-.309V2.4a.4.4 0 0 0-.4-.4H14v-.6a.4.4 0 0 0-.4-.4H3.4a.4.4 0 0 0-.4.4V2h-.6a.4.4 0 0 0-.4.4v20a.6.6 0 0 0 .6.6H7v-4.8c0-.11.09-.2.2-.2h2.6c.11 0 .2.09.2.2zM5.5 15V9h2v6h-2zm4-6v6h2V9h-2zm-4-2V4h2v3h-2zm4-3v3h2V4h-2zM17 17v-1h3v1h-3z" fill="#fff"></path><path d="M16.526 7l.079-1H19.6c.22 0 .4.18.4.4V7h-3.474z" fill="#fff"></path></svg><p style="width: 9.5rem; padding: 0.25rem; border-radius: 5px; font-weight: bold; position: relative; bottom: 1.75rem; left: 2.5rem; background: #fff;">улица 50 лет БССР, 7</p></span>'
      },
      settings: {
        apiKey: 'a36dc09e-0959-47b3-bfb3-fbb57d7459d9',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
.ymap-container {
  width: 100%;
  height: 100%;
}
[class*='ymaps-2'][class*='-ground-pane'] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
}
</style>
