<template>
  <section :class="$style.stock" id="inStock">
    <Title color="gray" position="center" weight="bold">ДВЕРИ В НАЛИЧИИ</Title>
    <div :class="$style.installation">
      <img src="@/assets/icons/installation.svg" />
      <p :class="$style.text">установка за день</p>
    </div>
    <Button :class="$style.left" type="secondary" @click="showPrev">
      <img src="@/assets/icons/arrowWhite.svg" />
    </Button>
    <VueSlickCarousel
      :class="$style.carousel"
      ref="inStockCarousel"
      v-bind="settings"
    >
      <div :class="$style.slider" v-for="door in inStockData" :key="door.id">
        <Slider :door="door" />
      </div>
    </VueSlickCarousel>
    <Button :class="$style.right" type="secondary" @click="showNext">
      <img :class="$style.icon" src="@/assets/icons/arrowWhite.svg" />
    </Button>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'
import Slider from '@/components/common/InStockSlider'
import { inStock } from '@/application/inStock'

export default {
  components: {
    Title,
    Button,
    Slider
  },
  computed: {
    inStockData() {
      return inStock
    }
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        draggable: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots inStock-dot-class',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              draggable: true
            }
          }
        ]
      }
    }
  },
  methods: {
    showNext() {
      this.$refs.inStockCarousel.next()
    },
    showPrev() {
      this.$refs.inStockCarousel.prev()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/breakpoints.scss';
.inStock-dot-class {
  bottom: -5rem;
}
@include for-tablet {
  .inStock-dot-class {
    bottom: -2.15rem;
  }
}
</style>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.stock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 89.375rem;
  margin: 0 auto 10rem;
  padding: 5.5rem 0 0;

  .installation {
    position: relative;
    display: flex;
    align-items: center;
    width: 10.25rem;
    padding: 0.15rem 0.75rem;
    margin: 1rem 0 4rem;
    background: $light-brown;

    &::before {
      content: '';
      position: absolute;
      left: 48%;
      bottom: -0.15rem;
      width: 0.5rem;
      height: 0.5rem;
      z-index: -1;
      background: $light-brown;
      transform: rotate(45deg);
    }

    .text {
      margin: 0 0 0 0.5rem;
      @include subtitle();
      font-variation-settings: 'wdth' 75;
    }
  }

  .left {
    position: absolute;
    top: 64%;
    left: 1rem;
    z-index: 10;
  }

  .right {
    position: absolute;
    top: 64%;
    right: 1rem;
    z-index: 10;
  }

  .icon {
    transform: rotate(180deg);
  }

  .carousel {
    width: 90%;

    .slider {
      height: 31.25rem;
      outline: none;
    }
  }
}

@include for-tablet {
  .stock {
    margin: 0 auto 5.5rem;
    padding: 3.5rem 0 0;

    .installation {
      margin: 0.75rem 0 2.5rem;
    }

    .left {
      top: 46.5%;
    }

    .right {
      top: 46.5%;
    }

    .carousel {
      width: 96%;

      .slider {
        height: 42rem;
      }
    }
  }
}

@include for-mobile {
  .stock {
    margin: 0 0 4.5rem;
    padding: 2.5rem 0.5rem 0;

    .installation {
      margin: 0.75rem 0 1.5rem;
    }

    .left {
      top: 38.5%;
    }

    .right {
      top: 38.5%;
    }

    .carousel {
      width: 100%;

      .slider {
        height: 48rem;
      }
    }
  }
}
</style>
