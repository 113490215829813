<template>
  <div :class="$style.form">
    <div :class="$style.inputs">
      <Input
        :class="$style.input"
        :type="inputType"
        v-model="name"
        :nameMask="nameMask"
        :placeholder="'Ваше имя'"
        :disabled="error.name && !firstFill"
        @input="isValid"
      />
      <Input
        :class="$style.input"
        :type="inputType"
        v-model="phone"
        :plus="true"
        :plusGray="color === 'grey'"
        :phoneMask="phoneMask"
        :placeholder="placeholder"
        :disabled="error.phone && !firstFill"
        @input="isValid"
      />
    </div>
    <div :class="$style.content">
      <Checkbox v-model="accept" :type="checkboxType" @input="isValid" />
      <p
        :class="[
          $style.text,
          $style[color],
          { [$style.text_error]: error.accept && !firstFill }
        ]"
      >
        Согласен на обработку персональных данных
      </p>
    </div>
    <Button
      :class="{ [$style.button__border]: !buttonBorder }"
      :type="buttonType"
      :line="buttonLine"
      :lineBrown="true"
      color="brown"
      @click="setCallRequest"
    >
      {{ buttonText }}
    </Button>
  </div>
</template>

<script>
import Input from '@/components/basic/Input'
import Checkbox from '@/components/basic/Checkbox'
import Button from '@/components/basic/Button'

import { converter } from '@/application/converter'

import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Input,
    Checkbox,
    Button
  },

  props: {
    inputType: {
      type: String,
      default: 'primary'
    },
    buttonType: {
      type: String,
      default: 'primary'
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonLine: {
      type: Boolean,
      default: true
    },
    buttonBorder: {
      type: Boolean,
      default: true
    },
    checkboxType: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      default: ''
    },
    calculator: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      accept: true,
      error: {
        name: false,
        phone: false,
        accept: false
      },
      firstFill: true,
      name: '',
      phone: '',
      nameMask: {
        mask: '*'.repeat(30),
        tokens: {
          '*': { pattern: /[a-zA-Zа-яА-Я\s]/ }
        }
      }
    }
  },

  computed: {
    ...mapGetters(['location', 'totalPrice']),

    phoneMask() {
      return this.location === 'BY' ? '###-##-###-##-##' : '#-###-###-##-##'
    },

    placeholder() {
      return this.location === 'BY' ? '___-__-___-__-__' : '_-___-___-__-__'
    }
  },

  methods: {
    ...mapActions(['postContactForm', 'postCalculatorForm']),
    ...mapMutations([
      'changeGratitudeStatus',
      'changeLoadingStatus',
      'changeFormStatus',
      'setName',
      'setPhone',
      'resetCalculator'
    ]),

    isEmpty(str) {
      return str.trim() === '' ? true : false
    },

    async setCallRequest() {
      if (this.isValid() === 3) {
        window.gtag_report_conversion()
        if (this.calculator) {
          this.changeLoadingStatus(true)
          await this.$store.dispatch('postCalculatorForm', {
            name: this.name,
            phone: this.phone,
            price: this.price(this.totalPrice)
          })
          this.changeLoadingStatus(false)
          this.changeGratitudeStatus(true)
          this.resetCalculator()
          this.$emit('changeStep')
          this.name = ''
          this.phone = ''
        } else {
          this.setName(this.name)
          this.setPhone(this.phone)
          this.changeFormStatus(false)
          this.changeLoadingStatus(true)
          await this.$store.dispatch('postContactForm')
          this.changeLoadingStatus(false)
          this.changeGratitudeStatus(true)
          this.name = ''
          this.phone = ''
        }
      } else {
        this.firstFill = false
      }
    },

    isValid() {
      if (this.isEmpty(this.name)) {
        this.error.name = true
      } else {
        this.error.name = false
      }
      if (
        !this.isEmpty(this.phone) &&
        this.phone.length >= (this.location === 'BY' ? 16 : 15)
      ) {
        this.error.phone = false
      } else {
        this.error.phone = true
      }
      if (this.accept) {
        this.error.accept = false
      } else {
        this.error.accept = true
      }
      let count = 0
      for (let key in this.error) {
        if (this.error[key] === false) {
          count++
        }
      }
      return count
    },

    price(num) {
      if (this.location === 'BY') {
        return `${this.priceSpase(num)} бел. руб.`
      } else {
        return `${this.priceSpase(num * converter.value)} руб.`
      }
    },

    priceSpase(price) {
      return String(price).length > 3
        ? String(price)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
            .replace(/\.[^.]+$/, '')
        : price
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';
@import '@/assets/styles/fonts/mixins.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .input {
      width: 49%;
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    .text {
      margin: 0 0.45rem;
      @include smallText();
      color: $white;
      opacity: 0.8;
      transition: all 0.3s ease-in-out;

      &.grey {
        color: $dark-gray;
      }

      &_error {
        color: $error !important;
        transform: translateX(15%) scale(1.3);
      }
    }
  }

  .button {
    &__border {
      border: none;
    }
  }
}

@include for-mobile {
  .form {
    .inputs {
      flex-direction: column;

      .input {
        width: 100%;
        margin: 0 0 0.5rem 0;

        &:last-child {
          margin: 0 0 1rem 0;
        }
      }
    }

    .content {
      align-items: flex-start;
      margin: 0 0 2.5rem;

      .text {
        &_error {
          transform: translateX(0%) scale(1);
        }
      }
    }
  }
}
</style>
