export default {
  state: {
    form: false,
    gratitude: false,
    loading: false
  },

  mutations: {
    changeFormStatus(state, status) {
      state.form = status
    },

    changeGratitudeStatus(state, status) {
      state.gratitude = status
    },

    changeLoadingStatus(state, status) {
      state.loading = status
    }
  },

  getters: {
    popup(state) {
      return state
    }
  }
}
