<template>
  <div :class="$style.popUpWrapper">
    <div :class="$style.bg" @click="close" />
    <div :class="$style.popUp">
      <div
        v-if="popup.form || popup.gratitude"
        :class="$style.close"
        @click="close"
      >
        <div :class="$style.icon" />
      </div>
      <div v-if="popup.form" :class="$style.form">
        <h3 :class="$style.title">
          Введите Ваши данные, <br />
          наш менеджер перезвонит Вам
        </h3>
        <Form
          :class="$style.form__wrapper"
          inputType="secondary"
          checkboxType="secondary"
          buttonType="popup"
          buttonText="Заказать"
          color="grey"
        />
      </div>
      <div v-else-if="popup.gratitude" :class="$style.gratitude">
        <h3 :class="$style.title">
          СПАСИБО, <br />
          ЧТО ОБРАТИЛИСЬ К НАМ
        </h3>
        <p :class="$style.text">
          В скором времени <br />
          Вам перезвонит менеджер
        </p>
      </div>
      <div v-else>
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="128"
          stroke="#538265"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/basic/Form'

import { mapMutations } from 'vuex'

export default {
  components: {
    Form
  },
  props: {
    popup: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapMutations(['changeFormStatus', 'changeGratitudeStatus']),

    close() {
      this.popup.form
        ? this.changeFormStatus(false)
        : this.changeGratitudeStatus(false)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.popUpWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;

  .bg {
    width: 100%;
    height: 100%;
    background: $transparent-green;
  }

  .popUp {
    position: fixed;
    top: 25%;
    left: 33%;
    z-index: 9500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34%;
    height: 23rem;
    padding: 5rem;
    background-color: $white;
    background-image: url('../../assets/images/pop-up-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .close {
      position: absolute;
      top: -1.1rem;
      right: -1.1rem;
      width: 2.25rem;
      height: 2.25rem;
      background: $dark-gray;
      cursor: pointer;

      .icon {
        position: relative;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          content: '';
          width: 2rem;
          height: 1px;
          background: $white;
          opacity: 0.5;
        }

        &::before {
          top: 1.125rem;
          right: 0.125rem;
          transform: rotate(45deg);
        }

        &::after {
          top: 1.125rem;
          right: 0.125rem;
          transform: rotate(135deg);
        }
      }
    }

    .title {
      margin: 0 0 2rem;
      @include bannerTitle();
      color: $dark-gray;
      text-align: center;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__wrapper {
        width: 100%;
        height: 70%;
      }
    }

    .gratitude {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80%;

      .text {
        @include subtitleText();
        color: $dark-gray;
        text-align: center;
        line-height: 160%;
      }
    }
  }
}

@include for-tablet {
  .popUpWrapper {
    .popUp {
      left: 20%;
      width: 60%;
      padding: 5rem 3rem;
    }
  }
}

@include for-mobile {
  .popUpWrapper {
    .popUp {
      top: 5rem;
      left: 10%;
      width: 80%;
      height: auto;
      padding: 2rem 0;

      .close {
        right: auto;
        left: auto;
      }

      .gratitude {
        .title {
          padding: 0 2rem;
        }
        .text {
          width: 105%;
        }
      }

      .form {
        padding: 1rem;
      }
    }
  }
}
</style>
