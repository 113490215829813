var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.feedback,
    ( _obj = {}, _obj[_vm.$style.active] = _vm.activeFeedback
        ? _vm.feedback.id === _vm.activeFeedback.id
        : false, _obj )
  ],on:{"click":_vm.setFeedback}},[_c('div',{class:[
      _vm.$style.image,
      ( _obj$1 = {}, _obj$1[_vm.$style.active] = _vm.activeFeedback
          ? _vm.feedback.id === _vm.activeFeedback.id
          : false, _obj$1 )
    ]},[_c('img',{class:[
        _vm.$style.img,
        ( _obj$2 = {}, _obj$2[_vm.$style.active] = _vm.activeFeedback
            ? _vm.feedback.id === _vm.activeFeedback.id
            : false, _obj$2 )
      ],attrs:{"src":require(("@/assets/images/feedbacks/" + (_vm.feedback.image) + ".jpg"))}})]),_c('div',{class:_vm.$style.description},[_c('p',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.feedback.name))]),_c('p',{class:_vm.$style.location},[_vm._v(_vm._s(_vm.feedback.location))])])])}
var staticRenderFns = []

export { render, staticRenderFns }